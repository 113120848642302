import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import CardHeader from "../../purity/components/Card/CardHeader";
import CardBody from "../../purity/components/Card/CardBody";
import Card from "../../purity/components/Card/Card";
import React, { useEffect, useState } from "react";
import { QueryResult, useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import { cdnUrl } from "../../environments/environments";
import { useNavigate } from "react-router-dom";
import { UI_Button } from "../UI_Components/UI_Button";
import { api_post_data } from "../../environments/api_handler";
import { AddIcon } from "@chakra-ui/icons";
import { Col, Row } from "react-bootstrap";
import { Typography } from "@mui/material";
import {
  TopVideoBlockPositions,
  TopVideoContent,
  TopVideoMediaTypes,
  TopVideoMediaVariants,
  TopVideoState,
} from "../../store/reducers/topmedia_info.reducer";
import { v4 } from "uuid";
import { get_top_video_position_label } from "./TopVideoEditor";
import { AppCoreLanguages } from "../../store/reducers/app_core_state.reducer";

const SingleBlockRows = ({
  overall_information_query,
}: {
  overall_information_query: QueryResult;
}) => {
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("#F8F9FA", "gray.800");
  const nameColor = useColorModeValue("gray.500", "white");

  const [topvideo_blocks, set_topvideo_blocks] = useState<TopVideoState[]>([]);

  useEffect(() => {
    const data = overall_information_query.data?.overall_information;

    if (data && data.top_videos) {
      set_topvideo_blocks(data.top_videos);
    }
  }, [overall_information_query.data]);

  const navigate = useNavigate();

  return (
    <>
      {topvideo_blocks.map((item, i) => (
        <Box key={i} p="24px" bg={bgColor} my="22px" borderRadius="12px">
          <Flex justify="space-between" w="100%">
            <Flex direction="column" maxWidth="70%">
              <Text color={nameColor} fontSize="md" fontWeight="bold" mb="10px">
                {item.de.main_text}
              </Text>
              {item.medias.main_img.type == TopVideoMediaTypes.image ? (
                <>
                  <img
                    src={`${cdnUrl}/${item.medias.main_img.url}`}
                    className="mb-3"
                    style={{
                      width: 100,
                      height: "auto",
                      borderRadius: "1rem",
                    }}
                  />
                </>
              ) : (
                <>
                  <video
                    autoPlay
                    loop
                    preload="metadata"
                    playsInline
                    className="mb-3"
                    muted
                    style={{
                      width: 100,
                      height: "auto",
                      borderRadius: "1rem",
                    }}
                    data-hd="true"
                  >
                    <source
                      src={`${cdnUrl}/${item.medias.main_img.url}`}
                      type="video/mp4"
                    />
                    {`Your browser doesn't support HTML5 video tag.`}
                  </video>
                </>
              )}
              <Text color={textColor} fontSize="sm" mb="10px">
                <strong>ID:</strong> {item.ID.substring(0, 10)}...
              </Text>
              <Text color={textColor} fontSize="sm" mb="10px">
                <strong>Vị trí:</strong>{" "}
                {get_top_video_position_label(item.block_position)}
              </Text>
            </Flex>
            <Flex
              direction={{ sm: "column", md: "row" }}
              align="flex-start"
              p={{ md: "24px" }}
            >
              <UI_Button
                color="error"
                className="mx-1"
                onClick={async () => {
                  if (!window.confirm("Are you sure?")) return;
                  await api_post_data(
                    "admin/delete_item_overall_information_arr_type",
                    {
                      name: "top_videos",
                      ID: item.ID,
                    },
                  );
                  await overall_information_query.refetch();
                }}
              >
                <Typography variant="body1">
                  <span className="me-2">
                    <i className="fa-light fa-trash-can" />
                  </span>
                  Xóa
                </Typography>
              </UI_Button>
              <UI_Button
                color="primary"
                className="mx-1"
                onClick={() => navigate(`/manage_topvideo_blocks/${item.ID}`)}
              >
                <Typography variant="body1">
                  <span className="me-2">
                    <i className="fa-light fa-pen" />
                  </span>
                  Sửa
                </Typography>
              </UI_Button>
            </Flex>
          </Flex>
        </Box>
      ))}
    </>
  );
};

export const ManageTopVideosBlocksPage = () => {
  const textColor = useColorModeValue("gray.700", "white");

  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      className="gap-3"
    >
      <Card my={{ lg: "24px" }} me={{ lg: "24px" }}>
        <Flex direction="column">
          <CardHeader py="12px">
            <Row className="justify-content-between w-100 align-items-center">
              <Col xs="auto" className="h-fit">
                <Text
                  color={textColor}
                  me="1rem"
                  fontSize="lg"
                  fontWeight="bold"
                >
                  Quản lý top videos
                </Text>
              </Col>
              <Col xs="auto" className="h-fit">
                <UI_Button
                  color="success"
                  onClick={async () => {
                    const langContent = (): {
                      [key in AppCoreLanguages]: TopVideoContent;
                    } => {
                      const initVal: any = {};
                      Object.values(AppCoreLanguages).forEach((item) => {
                        Object.assign(initVal, {
                          [item]: {
                            title_topleft: "title",
                            title_bottomleft: "title",
                            main_text: "Main text",
                          },
                        });
                      });
                      return initVal;
                    };
                    const top_videos: TopVideoState = {
                      ID: v4(),
                      block_position: TopVideoBlockPositions.homepage,
                      next_link: "/cases",
                      prev_link: "/cases",
                      medias: {
                        [TopVideoMediaVariants.main_img]: {
                          type: TopVideoMediaTypes.image,
                          url: "default_image_b34jk12.jpeg",
                        },
                      },
                      ...langContent(),
                    };
                    await api_post_data(
                      "admin/edit_overall_information_arr_type",
                      { top_videos },
                    );
                    await overall_information_query.refetch();
                  }}
                >
                  <AddIcon className="me-2" /> Thêm block
                </UI_Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Flex direction="column" w="100%">
              <SingleBlockRows
                overall_information_query={overall_information_query}
              />
            </Flex>
          </CardBody>
        </Flex>
      </Card>
    </Flex>
  );
};
