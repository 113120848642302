import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { UI_Button } from "../../UI_Components/UI_Button";
import { api_post_data } from "../../../environments/api_handler";
import { CheckIcon, ViewIcon } from "@chakra-ui/icons";
import React from "react";

export const BlockEditorArrTypeActionButtons = ({
  dataToSave,
  demoUrl,
}: {
  dataToSave: any;
  demoUrl?: string;
}) => {
  const navigate = useNavigate();

  return (
    <Row>
      <Col xs="auto">
        <UI_Button color="warning" onClick={() => navigate(-1)}>
          <span>
            <i className="fa-regular fa-left-long" />
          </span>
        </UI_Button>
      </Col>
      <Col xs="auto">
        <UI_Button
          color="success"
          onClick={async () => {
            await api_post_data(
              "admin/edit_overall_information_arr_type",
              dataToSave,
            );
            return;
          }}
        >
          <CheckIcon className="me-2" />
          <span>Lưu</span>
        </UI_Button>
      </Col>
      {demoUrl ? (
        <Col xs="auto">
          <UI_Button
            color="info"
            onClick={() => {
              navigate(demoUrl);
            }}
          >
            <ViewIcon className="me-2" />
            <span>Xem thử</span>
          </UI_Button>
        </Col>
      ) : (
        <></>
      )}
    </Row>
  );
};
