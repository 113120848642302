import { Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import {
  ProfileIcon,
  SettingsIcon,
} from "../../../purity/components/Icons/Icons";
import routes from "../../../purity/routes";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/models/corestore.interface";
import { api_post_data } from "../../../environments/api_handler";
import { CustomResponsiveSidebar } from "./CustomResponsiveSidebar";

export async function log_out() {
  await api_post_data("auth/log_out", null);
  window.location.reload();
  return;
}

export const CustomAdminNavBarLinks = (props: any) => {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;

  const userInfo = useSelector((e: AppState) => e.userInfo.data);

  // Chakra Color Mode
  const mainTeal = useColorModeValue("teal.300", "teal.300");
  const inputBg = useColorModeValue("white", "gray.800");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  const searchIcon = useColorModeValue("gray.700", "gray.200");

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }
  const settingsRef = React.useRef<any>();
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      justifyContent="end"
    >
      {userInfo.ID == 0 ? (
        <NavLink to="/auth/sign_in">
          <Button
            ms="0px"
            px="0px"
            me={{ sm: "2px", md: "16px" }}
            color={navbarIcon}
            variant="transparent-with-icon"
            rightIcon={
              document.documentElement.dir ? (
                <></>
              ) : (
                <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
              )
            }
            leftIcon={
              document.documentElement.dir ? (
                <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
              ) : (
                <></>
              )
            }
          >
            <Text display={{ sm: "none", md: "flex" }}>Sign In</Text>
          </Button>
        </NavLink>
      ) : (
        <Button
          ms="0px"
          px="0px"
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          variant="transparent-with-icon"
          onClick={async () => await log_out()}
          rightIcon={
            document.documentElement.dir ? (
              <></>
            ) : (
              <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
            )
          }
          leftIcon={
            document.documentElement.dir ? (
              <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
            ) : (
              <></>
            )
          }
        >
          <Text display={{ sm: "none", md: "flex" }}>Sign Out</Text>
        </Button>
      )}
      <CustomResponsiveSidebar
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        // logo={logo}
        {...rest}
      />
      <SettingsIcon
        cursor="pointer"
        ms={{ base: "16px", xl: "0px" }}
        me="16px"
        ref={settingsRef}
        onClick={props.onOpen}
        color={navbarIcon}
        w="18px"
        h="18px"
      />
    </Flex>
  );
};
