import {
  Flex,
  FormLabel,
  Grid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import CardHeader from "../../purity/components/Card/CardHeader";
import React, { useEffect, useRef, useState } from "react";
import Card from "../../purity/components/Card/Card";
import { QueryResult, useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import {
  UserInfo,
  UserRoles,
} from "../../models/interface/user_info.interface";
import CardBody from "../../purity/components/Card/CardBody";
import { Col, Row } from "react-bootstrap";
import { CircularProgress, Divider, Typography } from "@mui/material";
import { UI_Button } from "../UI_Components/UI_Button";
import { api_post_data } from "../../environments/api_handler";
import { Chakra_Input } from "../UI_Components/Chakra_Input";
import { v4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/models/corestore.interface";
import { cdnUrl } from "../../environments/environments";
import { AttachmentIcon, CheckIcon } from "@chakra-ui/icons";
import {
  AppGeneralInfoMediaVariants,
  set_app_general_info,
} from "../../store/reducers/app_general_info.reducer";
import { useNavigate } from "react-router-dom";
import { UI_TextField } from "../UI_Components/UI_TextField";

const ChangePageLogoCard = () => {
  const textColor = useColorModeValue("gray.700", "white");

  const BlockInfo = useSelector((e: AppState) => e.app_general_info);

  const dispatcher = useDispatch();

  const [is_waiting, set_is_waiting] = useState(false);

  async function upload_file(files: FileList) {
    set_is_waiting(true);
    const form_data = new FormData();
    form_data.append("overall_information_image", files[0]);
    await api_post_data("admin/upload_overall_information_image", form_data)
      .then((response) => {
        dispatcher(
          set_app_general_info({
            medias: {
              [AppGeneralInfoMediaVariants.logo_img]: {
                ...BlockInfo.medias.logo_img,
                url: response.data.payload.media_path,
              },
            },
          }),
        );
      })
      .catch((error) => console.log(error));
    set_is_waiting(false);
    return;
  }

  const addFileRef = useRef<any>(null);

  return (
    <Card p="16px">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Logo
        </Text>
      </CardHeader>
      <CardBody px="5px" className="flex-column">
        <Row className="justify-content-center">
          <Col>
            {is_waiting ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <>
                <Row className="mb-3 justify-content-center">
                  <Col xs="auto">
                    <img
                      alt="Logo image"
                      src={`${cdnUrl}/${BlockInfo.medias.logo_img.url}`}
                      style={{
                        height: "auto",
                        width: 200,
                        borderRadius: "1rem",
                        objectFit: "cover",
                        background: "#e0e0e0",
                        padding: "2rem",
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row className="justify-content-center mb-3">
              <Col xs="auto">
                <input
                  ref={addFileRef}
                  hidden
                  type="file"
                  name="upload"
                  onChange={async (e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      await upload_file(e.target.files);
                    }
                  }}
                />
                <UI_Button
                  variant="contained"
                  onClick={() => addFileRef.current.click()}
                >
                  <span className="me-2">
                    <AttachmentIcon />
                  </span>
                  Thay đổi hình ảnh
                </UI_Button>
              </Col>
            </Row>
            <Row className="mb-3">
              <FormLabel className="mb-4">Chiều cao:</FormLabel>
              <UI_TextField
                size="small"
                margin="none"
                label="Di động"
                value={BlockInfo.main_logo_height.mobile}
                onChange={(e) => {
                  dispatcher(
                    set_app_general_info({
                      main_logo_height: {
                        ...BlockInfo.main_logo_height,
                        mobile: e.target.value,
                      },
                    }),
                  );
                }}
              />
              <UI_TextField
                size="small"
                margin="none"
                label="Màn hình lớn"
                value={BlockInfo.main_logo_height.large}
                onChange={(e) => {
                  dispatcher(
                    set_app_general_info({
                      main_logo_height: {
                        ...BlockInfo.main_logo_height,
                        large: e.target.value,
                      },
                    }),
                  );
                }}
              />
            </Row>
            <Row className="justify-content-end">
              <Col xs="auto">
                <SaveGeneralInfoButtons />
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const SaveGeneralInfoButtons = () => {
  const navigate = useNavigate();

  const app_general_info = useSelector((e: AppState) => e.app_general_info);

  return (
    <Row>
      <Col xs="auto">
        <UI_Button
          color="warning"
          variant="contained"
          onClick={() => navigate(-1)}
        >
          <span>
            <i className="fa-regular fa-left-long" />
          </span>
        </UI_Button>
      </Col>
      <Col xs="auto">
        <UI_Button
          color="success"
          variant="contained"
          onClick={async () => {
            await api_post_data("admin/edit_overall_information", {
              app_general_info,
            });
            return;
          }}
        >
          <CheckIcon className="me-2" />
          <span>Lưu</span>
        </UI_Button>
      </Col>
    </Row>
  );
};

const AddNewAdminRow = (props: { all_users_query: QueryResult }) => {
  const [newAdmin, setNewAdmin] = useState<string>("");

  return (
    <>
      <Row>
        <Col xs="12">
          <Chakra_Input
            value={newAdmin}
            onChange={(newContent) => {
              setNewAdmin(newContent);
            }}
            placeholder="Nhập email"
          />
        </Col>
        <Col xs="12" className="text-end">
          <UI_Button
            variant="contained"
            color="success"
            onClick={async () => {
              await api_post_data("admin/set_admin", { email: newAdmin });
              await props.all_users_query.refetch();
            }}
          >
            <i className="fa-regular fa-plus me-2" />
            Thêm Admin
          </UI_Button>
        </Col>
      </Row>
    </>
  );
};

const AdminListCard = () => {
  const textColor = useColorModeValue("gray.700", "white");

  const all_users_query = useQuery(gql_queries.all_users);

  const [all_admin_users, set_all_admin_users] = useState<UserInfo[]>([]);

  useEffect(() => {
    const raw_data: UserInfo[] = all_users_query.data?.all_users;
    if (!raw_data) return;
    set_all_admin_users(
      raw_data.filter((item) => item.user_role == UserRoles.admin),
    );
  }, [all_users_query]);

  return (
    <Card p="16px">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Danh sách Admin
        </Text>
      </CardHeader>
      <CardBody px="5px" className="flex-column">
        {all_admin_users.map((item, i) => (
          <Row
            key={i}
            className="mb-3 justify-content-between align-items-center"
          >
            <Col xs="9" className="h-fit">
              <Typography variant="caption" className="mb-2">
                {item.user_email}
              </Typography>
            </Col>
            <Col xs="3" className="h-fit">
              <UI_Button
                color="error"
                onClick={async () => {
                  if (!confirm("Are you sure?")) return;
                  await api_post_data("admin/remove_admin", {
                    ID: item.ID,
                  });
                  await all_users_query.refetch();
                }}
              >
                Xóa
              </UI_Button>
            </Col>
          </Row>
        ))}
        <Row className="py-3">
          <Col>
            <Divider />
          </Col>
        </Row>
        <AddNewAdminRow all_users_query={all_users_query} />
      </CardBody>
    </Card>
  );
};

const AddEmailReceiverInput = (props: {
  overall_information_query: QueryResult;
}) => {
  const [new_receiver, set_new_receiver] = useState<string>("");

  return (
    <Row>
      <Col xs="12">
        <Chakra_Input
          value={new_receiver}
          onChange={(newContent) => {
            set_new_receiver(newContent);
          }}
          placeholder="Nhập email"
        />
      </Col>
      <Col xs="12" className="text-end">
        <UI_Button
          variant="contained"
          color="success"
          onClick={async () => {
            await api_post_data("admin/edit_overall_information_arr_type", {
              contact_page_email_receivers: {
                ID: v4(),
                email: new_receiver,
              },
            });
            await props.overall_information_query.refetch();
          }}
        >
          <i className="fa-regular fa-plus me-2" />
          Thêm người nhận
        </UI_Button>
      </Col>
    </Row>
  );
};

const EmailReceiverCard = () => {
  const textColor = useColorModeValue("gray.700", "white");

  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  const [user_list, set_user_list] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    const raw_data =
      overall_information_query.data?.overall_information
        .contact_page_email_receivers;
    if (!raw_data) return;
    set_user_list(raw_data);
  }, [overall_information_query.data]);

  return (
    <Card p="16px">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Danh sách Email nhận tin nhắn từ form Kontakt
        </Text>
      </CardHeader>
      <CardBody px="5px" className="flex-column">
        {user_list ? (
          <>
            {user_list.map((item, i) => (
              <Row
                key={i}
                className="mb-3 justify-content-between align-items-center"
              >
                <Col xs="9" className="h-fit">
                  <Typography variant="caption" className="mb-2">
                    {item.email}
                  </Typography>
                </Col>
                <Col xs="3" className="h-fit">
                  <UI_Button
                    color="error"
                    onClick={async () => {
                      if (!confirm("Are you sure?")) return;
                      await api_post_data(
                        "admin/delete_item_overall_information_arr_type",
                        {
                          name: "contact_page_email_receivers",
                          ID: item.ID,
                        },
                      );
                      await overall_information_query.refetch();
                    }}
                  >
                    Xóa
                  </UI_Button>
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <></>
        )}
        <Row className="py-3">
          <Col>
            <Divider />
          </Col>
        </Row>
        <AddEmailReceiverInput
          overall_information_query={overall_information_query}
        />
      </CardBody>
    </Card>
  );
};

export const PageGeneralInfoEditor = () => {
  const dispatcher = useDispatch();

  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const data =
      overall_information_query.data?.overall_information?.app_general_info;

    if (!data) return;

    dispatcher(set_app_general_info(data));
  }, [overall_information_query.data]);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)" }} gap="22px">
        <AdminListCard />
        <EmailReceiverCard />
        <ChangePageLogoCard />
      </Grid>
    </Flex>
  );
};
