import { BgOuterBox } from "../FE_Common/LandingPage/BgOuterBox";
import { VerticalLines } from "../FE_Common/LandingPage/verticallines/vertical_lines";
import { MyHomePageSingleBlock } from "../FE_Common/LandingPage/HomePageSingleBlock";
import { PurityAuthNavbar } from "../UI_Components/Purity_Auth_Navbar";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Typography } from "@mui/material";
import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import theme from "../../purity/theme/theme";
import { LanguageSwitcher } from "./children/LanguageSwitcher";
import { UI_Button } from "../UI_Components/UI_Button";

export const DemoPageNavBarContent = () => {
  const navigate = useNavigate();

  return (
    <>
      <Row className="justify-content-between w-100 align-items-center">
        <Col xs="auto" className="h-fit">
          <UI_Button variant="outlined" onClick={() => navigate(-1)}>
            <Typography variant="h6" component="div">
              <i className="fa-solid fa-left" />
            </Typography>
          </UI_Button>
        </Col>
        <Col xs="auto" className="h-fit">
          <LanguageSwitcher />
        </Col>
      </Row>
    </>
  );
};

export const HomePageSingleBlockDemoPage = () => {
  const OuterBoxDraft = BgOuterBox({
    bg: "#f0f0f0",
    xlHeight: "40vh",
    largeHeight: "40vh",
    phoneHeight: "40vh",
    tabletHeight: "40vh",
    smallPhoneHeight: "40vh",
  });

  return (
    <>
      <ChakraProvider theme={theme}>
        <PurityAuthNavbar content={<DemoPageNavBarContent />} />
      </ChakraProvider>
      <OuterBoxDraft>
        <VerticalLines bg="#212121" />
      </OuterBoxDraft>
      <MyHomePageSingleBlock />
    </>
  );
};
