import React, { useEffect } from "react";
import {
  setUserInfo,
  user_tried_signing_in,
} from "../../store/reducers/userinfo.reducer";
import { useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import { useNavigate } from "react-router-dom";

export const RequestUserInfo: React.FC = () => {
  const dispatcher = useDispatch();
  const navigate = useNavigate();

  const user_info_query = useQuery(gql_queries.user_info);

  useEffect(() => {
    const raw_data = user_info_query.data?.user_info;
    if (raw_data) {
      dispatcher(setUserInfo(raw_data));
    }

    if (user_info_query.error) {
      navigate("/auth/sign_in");
    }
    dispatcher(user_tried_signing_in(true));
  }, [user_info_query]);

  return <></>;
};
