import { Flex, FormLabel, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import CardHeader from "../../purity/components/Card/CardHeader";
import CardBody from "../../purity/components/Card/CardBody";
import { Col, Row } from "react-bootstrap";
import Card from "../../purity/components/Card/Card";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/models/corestore.interface";
import { api_post_data } from "../../environments/api_handler";
import {
  reset_blog_post_state,
  set_blog_post_info,
} from "../../store/reducers/blog_post_info.reducer";
import { cdnUrl } from "../../environments/environments";
import { Alert, CircularProgress, Divider, Typography } from "@mui/material";
import { UI_Button } from "../UI_Components/UI_Button";
import {
  AddCategoryButton,
  CategorySelector,
} from "./children/BlogPostCategoryEditor";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import JoditEditor from "jodit-react";
import { jodit_config } from "../../models/customJoditEditor";
import { AppCoreLanguages } from "../../store/reducers/app_core_state.reducer";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import { SingleBlogPostEntity } from "../../models/interface/single_blog_post.entity";
import { Chakra_Input } from "../UI_Components/Chakra_Input";

const Apply_Jodit_Content = new Subject<{
  lang: AppCoreLanguages;
  content: string;
}>();

const Post_Title = ({ lang }: { lang: AppCoreLanguages }) => {
  const dispatcher = useDispatch();

  const blog_post_info = useSelector((e: AppState) => e.blog_post);

  return (
    <Chakra_Input
      onChange={(newContent: string) => {
        dispatcher(
          set_blog_post_info({
            [lang]: {
              ...blog_post_info[lang],
              title: newContent,
            },
          }),
        );
      }}
      value={blog_post_info[lang].title ? blog_post_info[lang].title : ""}
      error={blog_post_info.touched && !blog_post_info[lang].title}
      placeholder="Tiêu đề"
    />
  );
};

const PostAvatar = () => {
  const current_avatar = useSelector((e: AppState) => e.blog_post.avatar);

  const addFileRef = useRef<any>();

  const dispatcher = useDispatch();

  const [is_waiting, set_is_waiting] = useState(false);

  async function upload_file(files: FileList) {
    set_is_waiting(true);
    const form_data = new FormData();
    form_data.append("blog_post_avatar", files[0]);
    await api_post_data("admin/upload_blog_post_avatar", form_data)
      .then((response) => {
        dispatcher(
          set_blog_post_info({ avatar: response.data.payload.media_path }),
        );
      })
      .catch((error) => console.log(error));
    set_is_waiting(false);
    return;
  }

  return (
    <>
      {current_avatar ? (
        <Row className="mb-3 justify-content-start">
          <Col xs="auto">
            <img
              src={`${cdnUrl}/${current_avatar}`}
              style={{
                borderRadius: 10,
                width: 300,
                height: "auto",
                maxWidth: "100%",
              }}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row>
        <Col className="text-start">
          <input
            ref={addFileRef}
            hidden
            type="file"
            name="upload"
            onChange={async (e) => {
              if (e.target.files && e.target.files.length > 0) {
                await upload_file(e.target.files);
              }
            }}
          />
          {is_waiting ? (
            <>
              <CircularProgress />
            </>
          ) : (
            <>
              <UI_Button
                color="success"
                variant="contained"
                onClick={() => addFileRef.current.click()}
              >
                <i className="fa-regular fa-image me-2" /> Ảnh đại diện
              </UI_Button>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const Post_Content = ({ lang }: { lang: AppCoreLanguages }) => {
  const dispatcher = useDispatch();

  const [jodit_content, set_jodit_content] = useState("");

  const [is_waiting, set_is_waiting] = useState(false);

  const blog_post_info = useSelector((e: AppState) => e.blog_post);

  let destroy$: any;

  useEffect(() => {
    destroy$ = new Subject();
    Apply_Jodit_Content.pipe(takeUntil(destroy$)).subscribe((res) => {
      if (res.lang == lang) {
        set_jodit_content(res.content);
      }
    });

    return () => {
      destroy$.next(false);
      destroy$.unsubscribe();
    };
  });

  async function upload_file(files: FileList) {
    set_is_waiting(true);
    const form_data = new FormData();
    form_data.append("blog_post_image", files[0]);
    await api_post_data("admin/upload_blog_post_image", form_data)
      .then((response) => {
        const new_content = `${blog_post_info[lang].content}<img src="${cdnUrl}/${response.data.payload.media_path}" width="300" />`;
        set_jodit_content(new_content);
      })
      .catch((error) => console.log(error));
    set_is_waiting(false);
    return;
  }

  function declare_post_images(content: string) {
    const img_set: any = [];
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");

    Array.from(doc.querySelectorAll("img")).forEach((img) => {
      const src = img.src;
      if (!src.includes(cdnUrl)) return;
      img_set.push(src.replace(`${cdnUrl}/`, ""));
    });

    return img_set;
  }

  const addFileRef = useRef<any>();

  return (
    <>
      <Row className="mb-3">
        <Col className="text-start">
          <Typography component="div">
            <JoditEditor
              value={jodit_content}
              config={jodit_config}
              onChange={(newContent) => {
                const images = declare_post_images(newContent);
                dispatcher(
                  set_blog_post_info({
                    [lang]: {
                      ...blog_post_info[lang],
                      content: newContent,
                      images,
                    },
                  }),
                );
              }}
            />
          </Typography>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="text-start">
          <input
            ref={addFileRef}
            hidden
            type="file"
            name="upload"
            onChange={async (e) => {
              if (e.target.files && e.target.files.length > 0) {
                await upload_file(e.target.files);
              }
            }}
          />
          {is_waiting ? (
            <>
              <CircularProgress />
            </>
          ) : (
            <>
              <UI_Button
                color="success"
                variant="contained"
                onClick={() => addFileRef.current.click()}
              >
                <i className="fa-regular fa-image me-2" /> Thêm ảnh cho bài viết
              </UI_Button>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const EditContentCard = ({
  lang,
  title,
}: {
  lang: AppCoreLanguages;
  title: string;
}) => {
  const textColor = useColorModeValue("gray.700", "white");
  const blog_post_info = useSelector((e: AppState) => e.blog_post);

  return (
    <Card p="16px">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          {title}
        </Text>
      </CardHeader>
      <CardBody px="5px" className="flex-column">
        <Row>
          <Col>
            <Post_Title lang={lang} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Post_Content lang={lang} />
          </Col>
        </Row>
        {blog_post_info.touched && !blog_post_info[lang].content ? (
          <>
            <Alert severity="warning" className="mb-3">
              Nội dung bài viết không được để trống
            </Alert>
          </>
        ) : (
          <></>
        )}
      </CardBody>
    </Card>
  );
};

const BasicOptionsCard = () => {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card p="16px">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Tùy chọn
        </Text>
      </CardHeader>
      <CardBody px="5px" className="flex-column">
        <Row className="mb-3">
          <Col xs="12" md="6">
            <PostAvatar />
          </Col>
          <Col xs="12" md="6">
            <CategorySelector />
            <Row className="justify-content-center">
              <Col xs="auto">
                <AddCategoryButton />
              </Col>
            </Row>
          </Col>
        </Row>

        <PostErrors />
      </CardBody>
    </Card>
  );
};

const PostErrors = () => {
  const blog_post_info = useSelector((e: AppState) => e.blog_post);

  return (
    <>
      {blog_post_info.touched && !blog_post_info.category ? (
        <Alert severity="warning" className="mb-3">
          Bạn phải chọn 1 danh mục
        </Alert>
      ) : (
        <></>
      )}
      {blog_post_info.touched && !blog_post_info.avatar ? (
        <Alert severity="warning" className="mb-3">
          Bạn phải đăng 1 ảnh đại diện cho bài viết này
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
};

const PostSubmitBtn = ({
  url_suffix,
  afterSubmitFn,
}: {
  url_suffix: string;
  afterSubmitFn?: () => void;
}) => {
  const dispatcher = useDispatch();
  const blog_post_info = useSelector((e: AppState) => e.blog_post);
  const navigate = useNavigate();

  return (
    <>
      <UI_Button color="info" className="me-2" onClick={() => navigate(-1)}>
        <Typography>
          <i className="fa-regular fa-left-long" />
        </Typography>
      </UI_Button>
      <UI_Button
        variant="contained"
        onClick={async () => {
          dispatcher(set_blog_post_info({ touched: true }));
          if (!blog_post_info.is_error) {
            const result = await api_post_data(url_suffix, blog_post_info);
            if (result.status == 201) {
              if (afterSubmitFn) afterSubmitFn();
            }
          }
          return;
        }}
      >
        Xác nhận
      </UI_Button>
    </>
  );
};

const PostBlockEditor = (props: {
  submit_link: string;
  afterSubmitFn?: () => any;
}) => {
  return (
    <>
      <BasicOptionsCard />
      <Row>
        <Col className="text-end">
          <PostSubmitBtn
            url_suffix={props.submit_link}
            afterSubmitFn={props.afterSubmitFn}
          />
        </Col>
      </Row>
      <Row className="py-4">
        <Col>
          <Divider>Chỉnh sửa nội dung</Divider>
        </Col>
      </Row>
      <EditContentCard lang={AppCoreLanguages.de} title="Nội dung tiếng Đức" />
      <Row>
        <Col className="text-end">
          <PostSubmitBtn
            url_suffix={props.submit_link}
            afterSubmitFn={props.afterSubmitFn}
          />
        </Col>
      </Row>
      <EditContentCard lang={AppCoreLanguages.en} title="Nội dung tiếng Anh" />
      <Row>
        <Col className="text-end">
          <PostSubmitBtn
            url_suffix={props.submit_link}
            afterSubmitFn={props.afterSubmitFn}
          />
        </Col>
      </Row>
      <EditContentCard lang={AppCoreLanguages.vi} title="Nội dung tiếng Việt" />
      <Row>
        <Col className="text-end">
          <PostSubmitBtn
            url_suffix={props.submit_link}
            afterSubmitFn={props.afterSubmitFn}
          />
        </Col>
      </Row>
      <EditContentCard lang={AppCoreLanguages.it} title="Nội dung tiếng Ý" />
      <Row>
        <Col className="text-end">
          <PostSubmitBtn
            url_suffix={props.submit_link}
            afterSubmitFn={props.afterSubmitFn}
          />
        </Col>
      </Row>
    </>
  );
};

export const CreateNewBlogPost = () => {
  const dispatcher = useDispatch();
  useEffect(() => {
    dispatcher(reset_blog_post_state());
  });

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      className="gap-3"
    >
      <FormLabel>Thêm bài viết mới</FormLabel>
      <PostBlockEditor
        submit_link="admin/submit_new_blog_post"
        afterSubmitFn={() => {
          Object.values(AppCoreLanguages).forEach((language) => {
            Apply_Jodit_Content.next({
              lang: language,
              content: Date.now().toString(),
            });
          });
          dispatcher(reset_blog_post_state());
        }}
      />
    </Flex>
  );
};

export const EditSingleBlogPost = () => {
  const dispatcher = useDispatch();
  const params = useParams();

  const BlockInfo = useSelector((e: AppState) => e.blog_post);
  const [post_info, set_post_info] = useState<any>(undefined);

  useEffect(() => {
    all_blog_post_query.refetch();
    dispatcher(set_blog_post_info(post_info));
  }, [post_info]);

  const all_blog_post_query = useQuery(gql_queries.all_blog_posts);

  useEffect(() => {
    const core_data: SingleBlogPostEntity[] =
      all_blog_post_query.data?.all_blog_posts;
    const postID = params.post_ID;
    if (core_data && postID) {
      const found_item: any = {
        ...core_data.find((p) => p.ID === parseInt(postID)),
      };
      if (!found_item) return;
      found_item.avatar = found_item.avatar.media_path;
      set_post_info(found_item);
      Object.values(AppCoreLanguages).forEach((language) => {
        Apply_Jodit_Content.next({
          lang: language,
          content: found_item[language]?.content,
        });
      });
    }
  }, [all_blog_post_query.data, params.post_ID]);

  return (
    <>
      <Flex
        direction="column"
        pt={{ base: "120px", md: "75px" }}
        className="gap-3"
      >
        <FormLabel>Chỉnh sửa bài viết</FormLabel>
        <Alert color="info">
          Bạn đang chỉnh sửa bài viết có ID #{BlockInfo.ID}
        </Alert>
        <PostBlockEditor submit_link="admin/edit_single_blog_post" />
      </Flex>
    </>
  );
};
