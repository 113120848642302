import { Outlet, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { UI_Snackbar } from "./UI_Components/UI_Snackbar";
import { UI_Dialog } from "./UI_Components/UI_Dialog";
import { RequestUserInfo } from "./shared/request_userinfo";

export const CoreWebLayout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname == "/") {
      navigate("/home");
    }
  });

  return (
    <>
      <UI_Snackbar />
      <UI_Dialog />
      <RequestUserInfo />
      <Outlet />
    </>
  );
};
