import { Flex, FormLabel, Text, useColorModeValue } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/models/corestore.interface";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import React, { useEffect, useRef, useState } from "react";
import Card from "../../purity/components/Card/Card";
import { CircularProgress } from "@mui/material";
import CardHeader from "../../purity/components/Card/CardHeader";
import CardBody from "../../purity/components/Card/CardBody";
import { Col, Row } from "react-bootstrap";
import { api_post_data } from "../../environments/api_handler";
import { cdnUrl } from "../../environments/environments";
import { UI_Button } from "../UI_Components/UI_Button";
import { AttachmentIcon, CheckIcon } from "@chakra-ui/icons";
import { jodit_config } from "../../models/customJoditEditor";

import JoditEditor from "jodit-react";
import { set_contact_page_info } from "../../store/reducers/contact_page_info.reducer";

const ChangeMediaCard = () => {
  const textColor = useColorModeValue("gray.700", "white");

  const BlockInfo = useSelector((e: AppState) => e.contact_page_info);

  const dispatcher = useDispatch();

  const [is_waiting, set_is_waiting] = useState(false);

  async function upload_file(files: FileList) {
    set_is_waiting(true);
    const form_data = new FormData();
    form_data.append("overall_information_image", files[0]);
    await api_post_data("admin/upload_overall_information_image", form_data)
      .then((response) => {
        dispatcher(
          set_contact_page_info({
            medias: {
              logo_img: {
                ...BlockInfo.medias.logo_img,
                url: response.data.payload.media_path,
              },
            },
          }),
        );
      })
      .catch((error) => console.log(error));
    set_is_waiting(false);
    return;
  }

  const addFileRef = useRef<any>(null);

  return (
    <Card p="16px">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Logo
        </Text>
      </CardHeader>
      <CardBody px="5px" className="flex-column">
        <Row className="justify-content-center">
          <Col xs="auto">
            {is_waiting ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <>
                <Row className="mb-3">
                  <Col>
                    <img
                      alt="Main image"
                      src={`${cdnUrl}/${BlockInfo.medias.logo_img.url}`}
                      style={{
                        height: "auto",
                        width: 200,
                        borderRadius: "1rem",
                        objectFit: "cover",
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row className="justify-content-center">
              <Col xs="auto">
                <input
                  ref={addFileRef}
                  hidden
                  type="file"
                  name="upload"
                  onChange={async (e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      await upload_file(e.target.files);
                    }
                  }}
                />
                <UI_Button
                  color="secondary"
                  variant="contained"
                  onClick={() => addFileRef.current.click()}
                >
                  <span className="me-2">
                    <AttachmentIcon />
                  </span>
                  Thay đổi hình ảnh
                </UI_Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const ActionButtons = () => {
  const contact_page_info = useSelector((e: AppState) => e.contact_page_info);

  const navigate = useNavigate();

  return (
    <Row>
      <Col xs="auto">
        <UI_Button color="warning" onClick={() => navigate(-1)}>
          <span>
            <i className="fa-regular fa-left-long" />
          </span>
        </UI_Button>
      </Col>
      <Col xs="auto">
        <UI_Button
          color="success"
          onClick={async () => {
            await api_post_data("admin/edit_overall_information", {
              contact_page_info,
            });
            return;
          }}
        >
          <CheckIcon className="me-2" />
          <span>Lưu</span>
        </UI_Button>
      </Col>
    </Row>
  );
};

export const ContactPageInfoEditor = () => {
  const dispatcher = useDispatch();

  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const data =
      overall_information_query.data?.overall_information?.contact_page_info;

    if (!data) return;

    dispatcher(set_contact_page_info(data));
  }, [overall_information_query.data]);

  const [jodit_content, set_jodit_content] = useState("");

  useEffect(() => {
    const data = overall_information_query.data?.overall_information;

    if (data && data.contact_page_info && data.contact_page_info.contact_info) {
      set_jodit_content(data.contact_page_info.contact_info);
    }
  }, [overall_information_query.data]);

  return (
    <>
      <Flex
        direction="column"
        pt={{ base: "120px", md: "75px" }}
        className="gap-3"
      >
        <FormLabel>Chỉnh sửa thông tin liên hệ</FormLabel>
        <Row>
          <Col xs="12" md="6">
            <Card p="16px">
              <CardBody px="5px" className="flex-column">
                <FormLabel>Chi tiết liên hệ:</FormLabel>
                <JoditEditor
                  value={jodit_content}
                  config={jodit_config}
                  onChange={(newContent) => {
                    dispatcher(
                      set_contact_page_info({
                        contact_info: newContent,
                      }),
                    );
                  }}
                />
                <Row className="mt-3 justify-content-end">
                  <Col xs="auto">
                    <ActionButtons />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" md="6">
            <ChangeMediaCard />
          </Col>
        </Row>
      </Flex>
    </>
  );
};
