import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/models/corestore.interface";
import { Flex, FormLabel, Text, useColorModeValue } from "@chakra-ui/react";
import { Chakra_Selector } from "../UI_Components/Chakra_Selector";
import { AppCoreLanguages } from "../../store/reducers/app_core_state.reducer";
import Card from "../../purity/components/Card/Card";
import CardHeader from "../../purity/components/Card/CardHeader";
import CardBody from "../../purity/components/Card/CardBody";
import { Col, Row } from "react-bootstrap";
import { Chakra_Input } from "../UI_Components/Chakra_Input";
import { UI_TextField } from "../UI_Components/UI_TextField";
import React, { useEffect, useRef, useState } from "react";
import { api_post_data } from "../../environments/api_handler";
import { Alert, CircularProgress, Divider } from "@mui/material";
import { cdnUrl } from "../../environments/environments";
import { UI_Button } from "../UI_Components/UI_Button";
import { AttachmentIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import {
  CasesPageMediaVariants,
  CasesPageSingleBlockModes,
  CasesPageSingleBlockTypes,
  set_cases_page_singleblock_info,
} from "../../store/reducers/cases_page_block_info.reducer";
import { BlockEditorArrTypeActionButtons } from "./children/BlockEditorArrTypeActionButtons";

const BlockTypeSelector = () => {
  const BlockInfo = useSelector((e: AppState) => e.cases_page_singleblock_info);

  const data_set = Object.keys(CasesPageSingleBlockTypes).map((item) => {
    return {
      value: item,
      label: item,
    };
  });

  const dispatcher = useDispatch();

  function myChangeEvent(e: any) {
    dispatcher(set_cases_page_singleblock_info({ type: e.target.value }));
  }

  return (
    <>
      <FormLabel>Kiểu mẫu</FormLabel>
      <Chakra_Selector
        value={BlockInfo.type}
        dataSet={data_set}
        onChange={myChangeEvent}
        placeholder="Kiểu mẫu"
      />
    </>
  );
};

const BlockModeSelector = () => {
  const BlockInfo = useSelector((e: AppState) => e.cases_page_singleblock_info);

  const label_set = ["Sáng", "Tối"];

  const data_set = Object.keys(CasesPageSingleBlockModes).map((item, i) => {
    return {
      value: item,
      label: label_set[i],
    };
  });

  const dispatcher = useDispatch();

  function myChangeEvent(e: any) {
    dispatcher(set_cases_page_singleblock_info({ mode: e.target.value }));
  }

  return (
    <>
      <FormLabel>Tông màu</FormLabel>
      <Chakra_Selector
        value={BlockInfo.mode}
        dataSet={data_set}
        onChange={myChangeEvent}
        placeholder="Tông màu"
      />
    </>
  );
};

const casespage_text_length = {
  title: 55,
  content: 300,
  index: 8,
  description: 20,
};

const ChangeContentBlock = ({
  lang,
  title,
}: {
  lang: AppCoreLanguages;
  title: string;
}) => {
  const textColor = useColorModeValue("gray.700", "white");

  const BlockInfo = useSelector((e: AppState) => e.cases_page_singleblock_info);

  const dispatcher = useDispatch();

  return (
    <Card p="16px">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          {title}
        </Text>
      </CardHeader>
      <CardBody px="5px" className="flex-column">
        <Row>
          <Col>
            <FormLabel>{`Tiêu đề chính (<${casespage_text_length.title} ký tự)`}</FormLabel>
            <Chakra_Input
              maxLength={casespage_text_length.title}
              onChange={(newContent: string) => {
                dispatcher(
                  set_cases_page_singleblock_info({
                    [lang]: {
                      ...BlockInfo[lang],
                      title: newContent,
                    },
                  }),
                );
              }}
              value={BlockInfo[lang].title}
              placeholder="Tiêu đề"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <UI_TextField
              inputProps={{
                maxLength: casespage_text_length.content,
              }}
              fullWidth
              onChange={(e) => {
                dispatcher(
                  set_cases_page_singleblock_info({
                    [lang]: {
                      ...BlockInfo[lang],
                      content: e.target.value,
                    },
                  }),
                );
              }}
              multiline
              label={`Nội dung chính (<${casespage_text_length.content} ký tự)`}
              minRows={5}
              value={BlockInfo[lang].content}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <FormLabel>{`Chỉ số 1 (<${casespage_text_length.index} ký tự)`}</FormLabel>
            <Chakra_Input
              maxLength={casespage_text_length.index}
              onChange={(newContent: string) => {
                dispatcher(
                  set_cases_page_singleblock_info({
                    [lang]: {
                      ...BlockInfo[lang],
                      first_number: newContent,
                    },
                  }),
                );
              }}
              value={BlockInfo[lang].first_number}
              placeholder="Chỉ số 1"
            />
            <FormLabel>{`Mô tả 1 (<${casespage_text_length.description} ký tự)`}</FormLabel>
            <Chakra_Input
              maxLength={casespage_text_length.description}
              onChange={(newContent: string) => {
                dispatcher(
                  set_cases_page_singleblock_info({
                    [lang]: {
                      ...BlockInfo[lang],
                      first_description: newContent,
                    },
                  }),
                );
              }}
              value={BlockInfo[lang].first_description}
              placeholder="Mô tả 1"
            />
          </Col>
          <Col xs="12" md="6">
            <FormLabel>{`Chỉ số 2 (<${casespage_text_length.index} ký tự)`}</FormLabel>
            <Chakra_Input
              maxLength={casespage_text_length.index}
              onChange={(newContent: string) => {
                dispatcher(
                  set_cases_page_singleblock_info({
                    [lang]: {
                      ...BlockInfo[lang],
                      second_number: newContent,
                    },
                  }),
                );
              }}
              value={BlockInfo[lang].second_number}
              placeholder="Chỉ số 2"
            />
            <FormLabel>{`Mô tả 2 (<${casespage_text_length.description} ký tự)`}</FormLabel>
            <Chakra_Input
              maxLength={casespage_text_length.description}
              onChange={(newContent: string) => {
                dispatcher(
                  set_cases_page_singleblock_info({
                    [lang]: {
                      ...BlockInfo[lang],
                      second_description: newContent,
                    },
                  }),
                );
              }}
              value={BlockInfo[lang].second_description}
              placeholder="Mô tả 2"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <ActionButtons />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const ChangeMediaCard = () => {
  const textColor = useColorModeValue("gray.700", "white");

  const BlockInfo = useSelector((e: AppState) => e.cases_page_singleblock_info);

  const dispatcher = useDispatch();

  const [is_waiting, set_is_waiting] = useState(false);

  async function upload_file(
    files: FileList,
    upload_type: CasesPageMediaVariants,
  ) {
    set_is_waiting(true);
    const form_data = new FormData();
    form_data.append("overall_information_image", files[0]);
    await api_post_data("admin/upload_overall_information_image", form_data)
      .then((response) => {
        dispatcher(
          set_cases_page_singleblock_info({
            medias: {
              ...BlockInfo.medias,
              [upload_type]: {
                ...BlockInfo.medias[upload_type],
                url: response.data.payload.media_path,
              },
            },
          }),
        );
      })
      .catch((error) => console.log(error));
    set_is_waiting(false);
    return;
  }

  const addFileRef_logo = useRef<any>(null);
  const addFileRef_mainimg = useRef<any>(null);
  const addFileRef_smallimg = useRef<any>(null);

  return (
    <Card p="16px">
      <CardHeader p="12px 5px" mb="12px">
        <Row className="w-100 justify-content-between">
          <Col xs="12" md="6">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Hình ảnh
            </Text>
          </Col>
          <Col xs="12" md="auto">
            <ActionButtons />
          </Col>
        </Row>
      </CardHeader>
      <CardBody px="5px" className="flex-column">
        <Row className="w-100">
          {/*Logo*/}
          <Col xs="12" md="4" className="mb-4 mb-md-0">
            <FormLabel>Ảnh Logo</FormLabel>
            {is_waiting ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <>
                <Row className="mb-3">
                  <Col>
                    <img
                      src={`${cdnUrl}/${BlockInfo.medias.logo_img.url}`}
                      style={{
                        minHeight: 200,
                        width: "auto",
                        borderRadius: "1rem",
                        objectFit: "cover",
                        background: "#e7e7e7",
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col>
                <input
                  ref={addFileRef_logo}
                  hidden
                  type="file"
                  name="upload"
                  onChange={async (e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      await upload_file(
                        e.target.files,
                        CasesPageMediaVariants.logo_img,
                      );
                    }
                  }}
                />
                <UI_Button
                  color="secondary"
                  variant="contained"
                  onClick={() => addFileRef_logo.current.click()}
                >
                  <span className="me-2">
                    <AttachmentIcon />
                  </span>
                  Thay đổi hình ảnh
                </UI_Button>
              </Col>
            </Row>
          </Col>
          {/*Main img*/}
          <Col xs="12" md="4" className="mb-4 mb-md-0">
            <FormLabel>Ảnh chính</FormLabel>
            {is_waiting ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <>
                <Row className="mb-3">
                  <Col>
                    <img
                      src={`${cdnUrl}/${BlockInfo.medias.main_img.url}`}
                      style={{
                        minHeight: 200,
                        width: "auto",
                        borderRadius: "1rem",
                        objectFit: "cover",
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col>
                <input
                  ref={addFileRef_mainimg}
                  hidden
                  type="file"
                  name="upload"
                  onChange={async (e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      await upload_file(
                        e.target.files,
                        CasesPageMediaVariants.main_img,
                      );
                    }
                  }}
                />
                <UI_Button
                  color="secondary"
                  variant="contained"
                  onClick={() => addFileRef_mainimg.current.click()}
                >
                  <span className="me-2">
                    <AttachmentIcon />
                  </span>
                  Thay đổi hình ảnh
                </UI_Button>
              </Col>
            </Row>
          </Col>
          {/*Small img*/}
          <Col xs="12" md="4">
            <FormLabel>Ảnh phụ</FormLabel>
            {is_waiting ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <>
                <Row className="mb-3">
                  <Col>
                    <img
                      src={`${cdnUrl}/${BlockInfo.medias.small_img.url}`}
                      style={{
                        minHeight: 200,
                        width: "auto",
                        borderRadius: "1rem",
                        objectFit: "cover",
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col>
                <input
                  ref={addFileRef_smallimg}
                  hidden
                  type="file"
                  name="upload"
                  onChange={async (e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      await upload_file(
                        e.target.files,
                        CasesPageMediaVariants.small_img,
                      );
                    }
                  }}
                />
                <UI_Button
                  color="secondary"
                  variant="contained"
                  onClick={() => addFileRef_smallimg.current.click()}
                >
                  <span className="me-2">
                    <AttachmentIcon />
                  </span>
                  Thay đổi hình ảnh
                </UI_Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const BlockOrderInput = () => {
  const BlockInfo = useSelector((e: AppState) => e.cases_page_singleblock_info);

  const dispatcher = useDispatch();

  return (
    <>
      <FormLabel>Thứ tự hiển thị</FormLabel>
      <Chakra_Input
        type="number"
        size="md"
        onChange={(newContent: number) => {
          dispatcher(
            set_cases_page_singleblock_info({
              order: newContent,
            }),
          );
        }}
        value={BlockInfo.order}
        placeholder="Thứ tự hiển thị"
      />
    </>
  );
};

const SeeMoreLinkInput = () => {
  const BlockInfo = useSelector((e: AppState) => e.cases_page_singleblock_info);
  const textColor = useColorModeValue("gray.700", "white");
  const dispatcher = useDispatch();

  return (
    <>
      <Flex direction="column">
        <FormLabel>{`Liên kết cho nút "Mehr erfahren"`}</FormLabel>
        <Chakra_Input
          size="md"
          onChange={(newContent: string) => {
            dispatcher(
              set_cases_page_singleblock_info({
                see_more_link: newContent,
              }),
            );
          }}
          value={BlockInfo.see_more_link}
          placeholder={`Liên kết cho nút "Mehr erfahren"`}
        />
        <Text color={textColor} fontSize="sm">
          <strong>Kết quả chỉnh sửa:</strong>
        </Text>
        <Text color={textColor} fontSize="sm">
          {window.location.origin}/{BlockInfo.see_more_link}
        </Text>
      </Flex>
    </>
  );
};

const ActionButtons = () => {
  const cases_page_blocks = useSelector(
    (e: AppState) => e.cases_page_singleblock_info,
  );

  return (
    <BlockEditorArrTypeActionButtons
      dataToSave={{ cases_page_blocks }}
      demoUrl="/demo/cases_page_singleblock"
    />
  );
};

export const CasesPageBlockEditor = () => {
  const textColor = useColorModeValue("gray.700", "white");

  const BlockInfo = useSelector((e: AppState) => e.cases_page_singleblock_info);

  const params = useParams();

  const dispatcher = useDispatch();

  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const data: any[] =
      overall_information_query.data?.overall_information?.cases_page_blocks;

    if (!data) return;

    const found = data.find((item: any) => item.ID == params.block_ID);
    if (!found) return;

    if (BlockInfo.ID != found.ID)
      dispatcher(set_cases_page_singleblock_info(found));
  }, [overall_information_query.data, params]);

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      className="gap-3"
    >
      <FormLabel>Quản lý block trang cases</FormLabel>
      <Alert color="info">Bạn đang chỉnh sửa block #{BlockInfo.ID}</Alert>
      <Card p="16px">
        <CardHeader p="12px 5px" mb="12px">
          <Text fontSize="lg" color={textColor} fontWeight="bold">
            Tùy chọn
          </Text>
        </CardHeader>
        <CardBody px="5px" className="flex-column">
          <Row className="w-100">
            <Col xs="12" md="4">
              <BlockTypeSelector />
            </Col>
            <Col xs="12" md="4">
              <BlockModeSelector />
            </Col>
            <Col xs="12" md="4">
              <BlockOrderInput />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs="12" md="6" className="h-fit">
              <SeeMoreLinkInput />
            </Col>
            <Col xs="12" md="6" className="h-fit">
              <Row className="justify-content-center">
                <Col xs="auto">
                  <ActionButtons />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Row className="py-4">
        <Col>
          <Divider>Chỉnh sửa nội dung</Divider>
        </Col>
      </Row>
      <ChangeContentBlock
        title="Nội dung tiếng Đức"
        lang={AppCoreLanguages.de}
      />
      <ChangeContentBlock
        title="Nội dung tiếng Anh"
        lang={AppCoreLanguages.en}
      />
      <ChangeContentBlock
        title="Nội dung tiếng Việt"
        lang={AppCoreLanguages.vi}
      />
      <ChangeContentBlock title="Nội dung tiếng Ý" lang={AppCoreLanguages.it} />
      <ChangeMediaCard />
    </Flex>
  );
};
