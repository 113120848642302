import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/models/corestore.interface";
import React from "react";
import {
  AppCoreLanguages,
  set_app_overall_info,
} from "../../../store/reducers/app_core_state.reducer";
import { Col, Row } from "react-bootstrap";

export const LanguageSwitcher = () => {
  const lang = useSelector((e: AppState) => e.app_overall_info.language);

  const dispatcher = useDispatch();

  function change_language() {
    switch (lang) {
      case AppCoreLanguages.de:
        dispatcher(set_app_overall_info({ language: AppCoreLanguages.vi }));
        break;
      case AppCoreLanguages.vi:
        dispatcher(set_app_overall_info({ language: AppCoreLanguages.en }));
        break;
      case AppCoreLanguages.en:
        dispatcher(set_app_overall_info({ language: AppCoreLanguages.de }));
        break;
    }
  }

  return (
    <>
      <Row>
        <Col>
          <span onClick={() => change_language()}>{lang.toUpperCase()}</span>
        </Col>
      </Row>
    </>
  );
};
