import { Flex, FormLabel, Text, useColorModeValue } from "@chakra-ui/react";
import CardHeader from "../../purity/components/Card/CardHeader";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Card from "../../purity/components/Card/Card";
import CardBody from "../../purity/components/Card/CardBody";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/models/corestore.interface";
import {
  HomepageMediaVariants,
  HomePageSingleBlockMediaTypes,
  HomePageSingleBlockModes,
  HomePageSingleBlockTypes,
  set_homepage_singleblock_info,
} from "../../store/reducers/homepage_singleblock_info.reducer";
import { AppCoreLanguages } from "../../store/reducers/app_core_state.reducer";
import { Col, Row } from "react-bootstrap";
import { Alert, CircularProgress, Divider, Typography } from "@mui/material";
import { Chakra_Input } from "../UI_Components/Chakra_Input";
import { Chakra_Selector } from "../UI_Components/Chakra_Selector";
import { UI_TextField } from "../UI_Components/UI_TextField";
import { AttachmentIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import { api_post_data } from "../../environments/api_handler";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import { cdnUrl } from "../../environments/environments";
import { UI_Button } from "../UI_Components/UI_Button";
import { BlockEditorArrTypeActionButtons } from "./children/BlockEditorArrTypeActionButtons";
import { homepage_block_text_length } from "./HomePageBlockEditor";

const BlockTypeSelector = () => {
  const BlockInfo = useSelector((e: AppState) => e.homepage_singleblock_info);

  const data_set = Object.keys(HomePageSingleBlockTypes).map((item) => {
    return {
      value: item,
      label: item,
    };
  });

  const dispatcher = useDispatch();

  function myChangeEvent(e: any) {
    dispatcher(set_homepage_singleblock_info({ type: e.target.value }));
  }

  return (
    <>
      <FormLabel>Kiểu mẫu</FormLabel>
      <Chakra_Selector
        value={BlockInfo.type}
        dataSet={data_set}
        onChange={myChangeEvent}
        placeholder="Kiểu mẫu"
      />
    </>
  );
};

const BlockModeSelector = () => {
  const BlockInfo = useSelector((e: AppState) => e.homepage_singleblock_info);

  const label_set = ["Sáng", "Tối"];

  const data_set = Object.keys(HomePageSingleBlockModes).map((item, i) => {
    return {
      value: item,
      label: label_set[i],
    };
  });

  const dispatcher = useDispatch();

  function myChangeEvent(e: any) {
    dispatcher(set_homepage_singleblock_info({ mode: e.target.value }));
  }

  return (
    <>
      <FormLabel>Tông màu</FormLabel>
      <Chakra_Selector
        value={BlockInfo.mode}
        dataSet={data_set}
        onChange={myChangeEvent}
        placeholder="Tông màu"
      />
    </>
  );
};

const ChangeContentBlock = ({
  lang,
  title,
}: {
  lang: AppCoreLanguages;
  title: string;
}) => {
  const textColor = useColorModeValue("gray.700", "white");

  const BlockInfo = useSelector((e: AppState) => e.homepage_singleblock_info);

  const dispatcher = useDispatch();

  return (
    <Card p="16px">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          {title}
        </Text>
      </CardHeader>
      <CardBody px="5px" className="flex-column">
        <Row>
          <Col xs="12" md="6">
            <FormLabel>Tiêu đề</FormLabel>
            <Chakra_Input
              maxLength={homepage_block_text_length.title}
              onChange={(newContent: string) => {
                dispatcher(
                  set_homepage_singleblock_info({
                    [lang]: {
                      ...BlockInfo[lang],
                      title: newContent,
                    },
                  }),
                );
              }}
              value={BlockInfo[lang].title}
              placeholder="Tiêu đề"
            />
          </Col>
          <Col xs="12" md="6">
            <FormLabel>{`Nội dung in đậm (<${homepage_block_text_length.sub_title} ký tự)`}</FormLabel>
            <Chakra_Input
              maxLength={homepage_block_text_length.sub_title}
              onChange={(newContent: string) => {
                dispatcher(
                  set_homepage_singleblock_info({
                    [lang]: {
                      ...BlockInfo[lang],
                      content_bold: newContent,
                    },
                  }),
                );
              }}
              value={BlockInfo[lang].content_bold}
              placeholder="Nội dung in đậm"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <UI_TextField
              inputProps={{
                maxLength: homepage_block_text_length.sub_content,
              }}
              fullWidth
              onChange={(e) => {
                dispatcher(
                  set_homepage_singleblock_info({
                    [lang]: {
                      ...BlockInfo[lang],
                      content_light: e.target.value,
                    },
                  }),
                );
              }}
              multiline
              label={`Nội dung phụ (<${homepage_block_text_length.sub_content} ký tự)`}
              minRows={5}
              value={BlockInfo[lang].content_light}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <ActionButtons />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const ChangeMediaCard = () => {
  const textColor = useColorModeValue("gray.700", "white");

  const BlockInfo = useSelector((e: AppState) => e.homepage_singleblock_info);

  const media_type_dataSet = Object.keys(HomePageSingleBlockMediaTypes).map(
    (item) => {
      return {
        value: item,
        label: item,
      };
    },
  );
  const dispatcher = useDispatch();

  const [is_waiting, set_is_waiting] = useState(false);

  async function upload_file(files: FileList) {
    set_is_waiting(true);
    const form_data = new FormData();
    form_data.append("overall_information_image", files[0]);
    await api_post_data("admin/upload_overall_information_image", form_data)
      .then((response) => {
        dispatcher(
          set_homepage_singleblock_info({
            medias: {
              ...BlockInfo.medias,
              main_img: {
                ...BlockInfo.medias.main_img,
                url: response.data.payload.media_path,
              },
            },
          }),
        );
      })
      .catch((error) => console.log(error));
    set_is_waiting(false);
    return;
  }

  const addFileRef = useRef<any>(null);

  return (
    <Card p="16px">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Hình ảnh
        </Text>
      </CardHeader>
      <CardBody px="5px" className="flex-column">
        <Row className="w-100">
          <Col xs="12" md="8" className="mb-4 mb-md-0">
            <Row>
              <Col>
                <FormLabel>Loại tệp</FormLabel>
                <Chakra_Selector
                  value={BlockInfo.medias.main_img.type}
                  dataSet={media_type_dataSet}
                  onChange={(e) =>
                    dispatcher(
                      set_homepage_singleblock_info({
                        medias: {
                          ...BlockInfo.medias,
                          main_img: {
                            ...BlockInfo.medias.main_img,
                            type: e.target.value,
                          },
                        },
                      }),
                    )
                  }
                  placeholder="Loại tệp"
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto">
                <ActionButtons />
              </Col>
            </Row>
          </Col>
          <Col xs="12" md="4" className="text-center">
            {is_waiting ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <>
                <Row className="mb-3">
                  <Col>
                    {BlockInfo.medias.main_img.type ==
                    HomePageSingleBlockMediaTypes.image ? (
                      <img
                        alt="Main image"
                        src={`${cdnUrl}/${BlockInfo.medias.main_img.url}`}
                        style={{
                          minHeight: 200,
                          width: "auto",
                          borderRadius: "1rem",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <video
                        autoPlay
                        loop
                        preload="metadata"
                        playsInline
                        muted
                        style={{
                          minHeight: 200,
                          width: "auto",
                          objectFit: "contain",
                          borderRadius: "1rem",
                        }}
                        data-hd="true"
                      >
                        <source
                          src={`${cdnUrl}/${BlockInfo.medias.main_img.url}`}
                          type="video/mp4"
                        />
                        {`Your browser doesn't support HTML5 video tag.`}
                      </video>
                    )}
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col>
                <input
                  ref={addFileRef}
                  hidden
                  type="file"
                  name="upload"
                  onChange={async (e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      await upload_file(e.target.files);
                    }
                  }}
                />
                <UI_Button
                  color="secondary"
                  variant="contained"
                  onClick={() => addFileRef.current.click()}
                >
                  <span className="me-2">
                    <AttachmentIcon />
                  </span>
                  Thay đổi hình ảnh
                </UI_Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const ChangeBlockAvatarOnHomePage = () => {
  const textColor = useColorModeValue("gray.700", "white");

  const BlockInfo = useSelector((e: AppState) => e.homepage_singleblock_info);

  const dispatcher = useDispatch();

  const media_type_dataSet = Object.keys(HomePageSingleBlockMediaTypes).map(
    (item) => {
      return {
        value: item,
        label: item,
      };
    },
  );

  const [is_waiting, set_is_waiting] = useState(false);

  async function upload_file(files: FileList) {
    set_is_waiting(true);
    const form_data = new FormData();
    form_data.append("overall_information_image", files[0]);
    await api_post_data("admin/upload_overall_information_image", form_data)
      .then((response) => {
        dispatcher(
          set_homepage_singleblock_info({
            medias: {
              ...BlockInfo.medias,
              [HomepageMediaVariants.homepage_img]: {
                ...BlockInfo.medias.homepage_img,
                url: response.data.payload.media_path,
              },
            },
          }),
        );
      })
      .catch((error) => console.log(error));
    set_is_waiting(false);
    return;
  }

  const addFileRef = useRef<any>(null);

  return (
    <Card p="16px">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Block avatar trên trang chủ
        </Text>
      </CardHeader>
      <CardBody px="5px" className="flex-column">
        <Row>
          <Col>
            <FormLabel>Loại tệp</FormLabel>
            <Chakra_Selector
              value={BlockInfo.medias.homepage_img?.type}
              dataSet={media_type_dataSet}
              onChange={(e) =>
                dispatcher(
                  set_homepage_singleblock_info({
                    medias: {
                      ...BlockInfo.medias,
                      [HomepageMediaVariants.homepage_img]: {
                        ...BlockInfo.medias.homepage_img,
                        type: e.target.value,
                      },
                    },
                  }),
                )
              }
              placeholder="Loại tệp"
            />
          </Col>
        </Row>
        <Row className="w-100">
          <Col className="text-center">
            {is_waiting ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <>
                <Row className="mb-3">
                  <Col>
                    <img
                      alt="Homepage avatar"
                      src={`${cdnUrl}/${BlockInfo.medias.homepage_img?.url}`}
                      style={{
                        minHeight: 200,
                        width: "auto",
                        borderRadius: "1rem",
                        objectFit: "cover",
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col>
                <input
                  ref={addFileRef}
                  hidden
                  type="file"
                  name="upload"
                  onChange={async (e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      await upload_file(e.target.files);
                    }
                  }}
                />
                <UI_Button
                  color="secondary"
                  variant="contained"
                  onClick={() => addFileRef.current.click()}
                >
                  <span className="me-2">
                    <AttachmentIcon />
                  </span>
                  Thay đổi hình ảnh
                </UI_Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const BlockOrderInput = () => {
  const BlockInfo = useSelector((e: AppState) => e.homepage_singleblock_info);

  const dispatcher = useDispatch();

  return (
    <>
      <FormLabel>Thứ tự hiển thị</FormLabel>
      <Chakra_Input
        type="number"
        size="md"
        onChange={(newContent: number) => {
          dispatcher(
            set_homepage_singleblock_info({
              order: newContent,
            }),
          );
        }}
        value={BlockInfo.order}
        placeholder="Thứ tự hiển thị"
      />
    </>
  );
};

const ActionButtons = () => {
  const servicesPageBlocks = useSelector(
    (e: AppState) => e.homepage_singleblock_info,
  );

  return (
    <BlockEditorArrTypeActionButtons
      dataToSave={{ services_page_blocks: servicesPageBlocks }}
      demoUrl="/demo/homepage_singleblock"
    />
  );
};

const ChangeServiceLandingPageBanner = () => {
  const BlockInfo = useSelector((e: AppState) => e.homepage_singleblock_info);

  const dispatcher = useDispatch();

  const media_type_dataSet = Object.keys(HomePageSingleBlockMediaTypes).map(
    (item) => {
      return {
        value: item,
        label: item,
      };
    },
  );

  const [is_waiting, set_is_waiting] = useState(false);

  async function upload_file(files: FileList) {
    set_is_waiting(true);
    const form_data = new FormData();
    form_data.append("overall_information_image", files[0]);
    await api_post_data("admin/upload_overall_information_image", form_data)
      .then((response) => {
        dispatcher(
          set_homepage_singleblock_info({
            medias: {
              ...BlockInfo.medias,
              [HomepageMediaVariants.service_landing_img]: {
                ...BlockInfo.medias.service_landing_img,
                url: response.data.payload.media_path,
              },
            },
          }),
        );
      })
      .catch((error) => console.log(error));
    set_is_waiting(false);
    return;
  }

  const addFileRef = useRef<any>(null);

  return (
    <>
      <Row>
        <Col>
          <Chakra_Selector
            value={
              BlockInfo.medias.service_landing_img?.type == undefined
                ? ""
                : BlockInfo.medias.service_landing_img?.type
            }
            dataSet={media_type_dataSet}
            onChange={(e) =>
              dispatcher(
                set_homepage_singleblock_info({
                  medias: {
                    ...BlockInfo.medias,
                    [HomepageMediaVariants.service_landing_img]: {
                      ...BlockInfo.medias.service_landing_img,
                      type: e.target.value,
                    },
                  },
                }),
              )
            }
            placeholder="Loại tệp"
          />
        </Col>
      </Row>
      <Row className="w-100">
        <Col className="text-center">
          {is_waiting ? (
            <>
              <CircularProgress />
            </>
          ) : (
            <>
              <Row className="mb-3">
                <Col>
                  {BlockInfo.medias.service_landing_img?.type ==
                  HomePageSingleBlockMediaTypes.image ? (
                    <img
                      src={`${cdnUrl}/${BlockInfo.medias.service_landing_img?.url}`}
                      style={{
                        minHeight: 200,
                        width: "auto",
                        borderRadius: "1rem",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <video
                      autoPlay
                      loop
                      preload="metadata"
                      playsInline
                      muted
                      style={{
                        minHeight: 200,
                        width: "auto",
                        objectFit: "contain",
                        borderRadius: "1rem",
                      }}
                      data-hd="true"
                    >
                      <source
                        src={`${cdnUrl}/${BlockInfo.medias.service_landing_img?.url}`}
                        type="video/mp4"
                      />
                      {`Your browser doesn't support HTML5 video tag.`}
                    </video>
                  )}
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col>
              <input
                ref={addFileRef}
                hidden
                type="file"
                name="upload"
                onChange={async (e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    await upload_file(e.target.files);
                  }
                }}
              />
              <UI_Button
                color="secondary"
                variant="contained"
                onClick={() => addFileRef.current.click()}
              >
                <span className="me-2">
                  <AttachmentIcon />
                </span>
                Thay đổi hình ảnh
              </UI_Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const ChangeServiceBlockLandingCard = () => {
  const dispatcher = useDispatch();
  const BlockInfo = useSelector((e: AppState) => e.homepage_singleblock_info);
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <>
      <Card p="16px">
        <CardHeader p="12px 5px" mb="12px">
          <Text fontSize="lg" color={textColor} fontWeight="bold">
            Thông tin landing page riêng của Service
          </Text>
        </CardHeader>
        <CardBody px="5px" className="d-flex flex-column gap-2">
          <FormLabel>Tên đường dẫn landing page</FormLabel>
          <Chakra_Input
            size="md"
            onChange={(newContent) => {
              dispatcher(
                set_homepage_singleblock_info({
                  service_url: newContent,
                }),
              );
            }}
            value={BlockInfo.service_url}
            placeholder="Nhập slug. Vd: web-design"
          />
          <FormLabel>Tên dịch vụ trên menu tổng</FormLabel>
          {Object.values(AppCoreLanguages).map((single_lang, i) => (
            <Fragment key={i}>
              <Typography className="mb-2">
                {single_lang.toUpperCase()}
              </Typography>
              <Chakra_Input
                size="md"
                onChange={(newContent) => {
                  dispatcher(
                    set_homepage_singleblock_info({
                      [single_lang]: {
                        ...BlockInfo[single_lang],
                        service_info_mainmenu_name: newContent,
                      },
                    }),
                  );
                }}
                value={
                  BlockInfo[single_lang].service_info_mainmenu_name == undefined
                    ? ""
                    : BlockInfo[single_lang].service_info_mainmenu_name
                }
                placeholder={`[${single_lang.toUpperCase()}] Tên menu của dịch vụ`}
              />
            </Fragment>
          ))}
          <FormLabel>Banner</FormLabel>
          <ChangeServiceLandingPageBanner />
          <Row className="justify-content-center">
            <Col xs="auto">
              <ActionButtons />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export const ServicesPageBlockEditor = () => {
  const textColor = useColorModeValue("gray.700", "white");

  const BlockInfo = useSelector((e: AppState) => e.homepage_singleblock_info);

  const params = useParams();

  const dispatcher = useDispatch();

  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const data: any[] =
      overall_information_query.data?.overall_information?.services_page_blocks;

    if (!data) return;

    const found = data.find((item: any) => item.ID == params.block_ID);
    if (!found) return;

    if (BlockInfo.ID != found.ID)
      dispatcher(set_homepage_singleblock_info(found));
  }, [overall_information_query.data, params]);

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      className="gap-3"
    >
      <FormLabel>Quản lý block trang services</FormLabel>
      <Alert color="info">Bạn đang chỉnh sửa block #{BlockInfo.ID}</Alert>
      <Card p="16px">
        <CardHeader p="12px 5px" mb="12px">
          <Text fontSize="lg" color={textColor} fontWeight="bold">
            Tùy chọn
          </Text>
        </CardHeader>
        <CardBody px="5px" className="flex-column">
          <Row className="w-100">
            <Col xs="12" md="4">
              <BlockTypeSelector />
            </Col>
            <Col xs="12" md="4">
              <BlockModeSelector />
            </Col>
            <Col xs="12" md="4">
              <BlockOrderInput />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="auto">
              <ActionButtons />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Row className="py-4">
        <Col>
          <Divider>Chỉnh sửa nội dung</Divider>
        </Col>
      </Row>
      <ChangeContentBlock
        title="Nội dung tiếng Đức"
        lang={AppCoreLanguages.de}
      />
      <ChangeContentBlock
        title="Nội dung tiếng Anh"
        lang={AppCoreLanguages.en}
      />
      <ChangeContentBlock
        title="Nội dung tiếng Việt"
        lang={AppCoreLanguages.vi}
      />
      <ChangeContentBlock title="Nội dung tiếng Ý" lang={AppCoreLanguages.it} />
      <Row>
        <Col xs="12" md="8" className="mb-4 gap-4 d-flex flex-column">
          <ChangeMediaCard />
          <ChangeServiceBlockLandingCard />
        </Col>
        <Col xs="12" md="4">
          <ChangeBlockAvatarOnHomePage />
        </Col>
      </Row>
    </Flex>
  );
};
