import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Box, Typography } from "@mui/material";
import { BgOuterBox } from "./BgOuterBox";
import { BigBoxInner } from "./others";
import { VerticalLines } from "./verticallines/vertical_lines";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../../models/interface/gql_queries";
import { FooterContactLogoState } from "../../../store/reducers/footer_contact_logo_info.reducer";
import { cdnUrl } from "../../../environments/environments";
import { FooterPartnerLogoState } from "../../../store/reducers/footer_partner_info.reducer";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/models/corestore.interface";
import { all_screen_size } from "../../../store/reducers/screensize.reducer";
import { useNavigate } from "react-router-dom";
import { AppCoreLanguages } from "../../../store/reducers/app_core_state.reducer";
import styled from "@emotion/styled";
import { toggleServiceItemsMenu } from "./MenuButtons/menubuttons";

export const FooterContactBtn = ({
  text,
  url,
  textColor,
}: {
  text: string;
  url: string;
  textColor?: string;
}) => {
  function hovering() {
    if (
      firstline &&
      firstline.current &&
      lastline &&
      lastline.current &&
      myText &&
      myText.current
    ) {
      gsap.to(firstline.current, { opacity: 1 });
      gsap.to(myText.current, { x: 5 });
      gsap.to(lastline.current, { width: "100%" });
    }
  }

  function focusOut() {
    if (
      firstline &&
      firstline.current &&
      lastline &&
      lastline.current &&
      myText &&
      myText.current
    ) {
      gsap.to(firstline.current, { opacity: 0 });
      gsap.to(myText.current, { x: 0 });
      gsap.to(lastline.current, { width: 0 });
    }
  }

  const firstline = useRef<any>();
  const myText = useRef<any>();
  const lastline = useRef<any>();

  const navigate = useNavigate();

  return (
    <Row
      style={{
        width: 400,
        maxWidth: "100%",
      }}
      className="c-pointer"
      onClick={() => navigate(url)}
      onMouseEnter={() => hovering()}
      onMouseLeave={() => focusOut()}
    >
      <Col xs="auto" className="px-0">
        <div
          className="d-flex h-100 justify-content-center align-items-center"
          style={{
            width: 10,
          }}
        >
          <Box
            ref={firstline}
            style={{
              opacity: 0,
              height: 1,
              width: 10,
              background: textColor ? textColor : "#000",
            }}
          />
        </div>
      </Col>
      <Col xs="auto">
        <Typography
          variant="h6"
          fontWeight="bold"
          component="div"
          ref={myText}
          style={{
            color: textColor ? textColor : "inherit",
          }}
        >
          {text}
        </Typography>
      </Col>
      <Col>
        <div className="d-flex h-100 justify-content-start align-items-center">
          <Box
            style={{
              height: 1,
              width: 0,
              background: textColor ? textColor : "#000",
            }}
            ref={lastline}
          />
        </div>
      </Col>
    </Row>
  );
};

export const FooterSmallBox = () => {
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const arr1 = useRef<any>(null);
  const arr2 = useRef<any>(null);

  function myHover() {
    if (arr1 && arr1.current && arr2 && arr2.current) {
      gsap.to(arr1.current, { left: "150%" });
      gsap.to(arr2.current, { left: "50%" });
    }
  }

  function focusOut() {
    if (arr1 && arr1.current && arr2 && arr2.current) {
      gsap.to(arr1.current, { left: "50%" });
      gsap.to(arr2.current, { left: "-50%" });
    }
  }

  const navigate = useNavigate();

  const lang = useSelector((e: AppState) => e.app_overall_info.language);

  return (
    <Box
      onMouseEnter={() => myHover()}
      onMouseLeave={() => focusOut()}
      className="c-pointer"
      style={{
        width: 400,
        maxWidth: "90%",
        height: "100%",
        minHeight: 200,
        border: "black solid 1px",
        position: "relative",
      }}
    >
      <Box
        className="d-flex justify-content-center align-items-center p-4"
        style={{
          height: "75%",
          width: "100%",
        }}
      >
        <Typography
          component="div"
          variant="h6"
          className="text-uppercase fw-bold"
        >
          {(() => {
            switch (lang) {
              case AppCoreLanguages.de:
                return "INTERESSE GEWECKT?";
              case AppCoreLanguages.vi:
                return "Bạn có câu hỏi?";
              case AppCoreLanguages.en:
                return "Are you interested?";
              case AppCoreLanguages.it:
                return "Sei interessato?";
            }
          })()}
        </Typography>
      </Box>
      <Box
        className="d-flex h-25 bg-black text-white"
        onClick={() => navigate("/kontakt")}
      >
        <Box className="w-75 h-100 d-flex justify-content-center align-items-center">
          <Typography
            variant={is_mobile() ? "body1" : "h6"}
            className="text-uppercase"
            fontWeight="bold"
            component="div"
          >
            {`Let's connect`}
          </Typography>
        </Box>
        <Box className="w-25 h-100 position-relative overflow-hidden">
          <svg
            ref={arr1}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 800 800"
            style={{
              position: "absolute",
              transform: "translate(-50%, -50%)",
              left: "50%",
              top: "50%",
              width: "60%",
            }}
          >
            <g
              strokeWidth="40"
              stroke="#fff"
              fill="#fff"
              strokeLinecap="square"
              transform="rotate(315, 400, 400)"
            >
              <line
                x1="250"
                y1="250"
                x2="550"
                y2="550"
                markerEnd="url(#SvgjsMarker1248)"
              />
            </g>
            <defs>
              <marker
                markerWidth="5"
                markerHeight="5"
                refX="2.5"
                refY="2.5"
                viewBox="0 0 5 5"
                orient="auto"
                id="SvgjsMarker1248"
              >
                <polygon points="0,5 2.5,2.5 0,0 5,2.5" fill="#fff" />
              </marker>
            </defs>
          </svg>
          <svg
            ref={arr2}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 800 800"
            style={{
              position: "absolute",
              transform: "translate(-50%, -50%)",
              left: "-50%",
              top: "50%",
              width: "60%",
            }}
          >
            <g
              strokeWidth="40"
              stroke="#fff"
              fill="#fff"
              strokeLinecap="square"
              transform="rotate(315, 400, 400)"
            >
              <line
                x1="250"
                y1="250"
                x2="550"
                y2="550"
                markerEnd="url(#SvgjsMarker1248)"
              />
            </g>
            <defs>
              <marker
                markerWidth="5"
                markerHeight="5"
                refX="2.5"
                refY="2.5"
                viewBox="0 0 5 5"
                orient="auto"
                id="SvgjsMarker1248"
              >
                <polygon points="0,5 2.5,2.5 0,0 5,2.5" fill="#fff" />
              </marker>
            </defs>
          </svg>
        </Box>
      </Box>
    </Box>
  );
};

export type FooterOneMenuListEntity = {
  [key in AppCoreLanguages]: string;
} & {
  url: string;
  main?: boolean;
  order?: number;
  hasAction: boolean;
  action: () => any;
};

export const FooterMenuList: FooterOneMenuListEntity[] = [
  {
    de: "Agentur",
    main: true,
    url: "/home",
    order: 1,
    vi: "Trang chủ",
    en: "Home",
    it: "Agentur",
    hasAction: false,
    action: () => {
      return true;
    },
  },
  {
    de: "Blog",
    url: "/blog",
    vi: "Blog",
    en: "Blog",
    it: "Blog",
    hasAction: false,
    action: () => {
      return true;
    },
  },
  {
    de: "Datenschutz",
    url: "/datenschutz",
    vi: "Bảo mật",
    en: "Privacy",
    it: "Privacy",
    hasAction: false,
    action: () => {
      return true;
    },
  },
  {
    de: "Services",
    main: true,
    url: "/services",
    order: 2,
    vi: "Dịch vụ",
    en: "Services",
    it: "Services",
    hasAction: true,
    action: () => {
      toggleServiceItemsMenu.next(true);
    },
  },
  {
    de: "Cases",
    main: true,
    url: "/cases",
    order: 3,
    vi: "Cases",
    en: "Cases",
    it: "Cases",
    hasAction: false,
    action: () => {
      return true;
    },
  },
  {
    de: "Impressum",
    url: "/impressum",
    vi: "Bản quyền",
    en: "Authorship",
    it: "Paternità",
    hasAction: false,
    action: () => {
      return true;
    },
  },
  {
    de: "Kontakt",
    main: true,
    url: "/kontakt",
    order: 4,
    vi: "Liên hệ",
    en: "Contact",
    it: "Contatto",
    hasAction: false,
    action: () => {
      return true;
    },
  },
];

export const FooterOne = () => {
  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  const [all_contact_logos, set_all_contact_logos] = useState<
    FooterContactLogoState[]
  >([]);

  const [all_partner_logos, set_all_partner_logos] = useState<
    FooterPartnerLogoState[]
  >([]);

  useEffect(() => {
    const footer_contact_logos: any[] =
      overall_information_query.data?.overall_information?.footer_contact_logos;
    const footer_partner_logos: any[] =
      overall_information_query.data?.overall_information?.footer_partner_logos;

    if (!footer_contact_logos || !footer_partner_logos) return;

    set_all_contact_logos(footer_contact_logos);
    set_all_partner_logos(footer_partner_logos);
  }, [overall_information_query.data]);

  const OuterBox = BgOuterBox({
    bg: "#f0f0f0",
    autoHeight: true,
  });
  const InnerBox = BigBoxInner({ fluid: true });

  const lang = useSelector((e: AppState) => e.app_overall_info.language);

  const ArrowBox = styled(Box)`
    position: absolute;
    right: 10px;
    top: 3rem;
    border: 1px solid black;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
  `;

  return (
    <OuterBox>
      <InnerBox>
        <Row className="align-items-end" style={{ minHeight: "100%" }}>
          <Col className="z-10" xs="auto">
            <Row
              className="align-items-end py-5 px-2 px-md-4"
              style={{ minHeight: "fit-content" }}
            >
              <Col xs="12">
                <Row>
                  <Col xs="8" sm="4" className="mb-5 mb-sm-0">
                    <FooterSmallBox />
                  </Col>
                  <Col xs="12" sm="6" lg="4">
                    <Row>
                      {FooterMenuList.map((item, i) => (
                        <Col key={i} xs="6" className="px-0 mb-4">
                          <FooterContactBtn url={item.url} text={item[lang]} />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs="12">
                <Row>
                  <Col xs="12" sm="6" lg="4" className="text-start">
                    <Typography
                      variant="h5"
                      className="my-5 fw-bold text-uppercase"
                    >
                      Social Media
                    </Typography>
                    <Row className="gap-4">
                      {all_contact_logos.map((item, i) => (
                        <Col xs="auto" key={i}>
                          <Typography variant="h4">
                            <a
                              href={item.url}
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: "inherit" }}
                            >
                              <i className={`fa-brands fa-${item.logo}`} />
                            </a>
                          </Typography>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Col xs="12" sm="6" lg="4">
                    <Typography
                      variant="h5"
                      className="my-5 fw-bold text-uppercase text-start"
                    >
                      Partners
                    </Typography>
                    <Row className="align-items-center">
                      {all_partner_logos.map((item, i) => (
                        <Col xs="auto" className="h-fit" key={i}>
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "inherit" }}
                          >
                            <img
                              width="75"
                              height="auto"
                              src={`${cdnUrl}/${item.medias.main_img.url}`}
                            />
                          </a>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </InnerBox>
      <ArrowBox onClick={() => window.scrollTo(0, 0)}>
        <Typography component="div" variant="h4">
          <i className="fa-light fa-angle-up" />
        </Typography>
      </ArrowBox>
      <VerticalLines bg="#212121" />
    </OuterBox>
  );
};
