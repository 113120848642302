import React, { ReactNode } from "react";
import { Outlet, Route } from "react-router-dom";
import { HomePageBlockEditor } from "../components/LandingPage/HomePageBlockEditor";
import { DashboardHome } from "../components/LandingPage/DashboardHome";
import { HomePageSingleBlockDemoPage } from "../components/LandingPage/HomePageSingleBlockDemoPage";
import { ManageHomepageBlocksPage } from "../components/LandingPage/ManageHomepageBlocksPage";
import { AuthLayout } from "../components/Layouts/AuthLayout";
import { SignInPage } from "../components/shared/SignInPage";
import { MyAdminLayout } from "../components/Layouts/MyAdminLayout";
import { TopVideoDemoPage } from "../components/LandingPage/TopVideoDemoPage";
import { ManageTopVideosBlocksPage } from "../components/LandingPage/ManageTopVideoBlocksPage";
import { TopVideoEditor } from "../components/LandingPage/TopVideoEditor";
import { ManageCasesPageBlocksPage } from "../components/LandingPage/ManageCasesPageBlockPage";
import { CasesPageBlockEditor } from "../components/LandingPage/CasesPageBlockEditor";
import { CasesPageSingleBlockDemoPage } from "../components/LandingPage/CasesPageSingleBlockDemoPage";
import { HomepagePartnerSectionEditor } from "../components/LandingPage/HomepagePartnerSectionEditor";
import { ManageFooterContactLogoPage } from "../components/LandingPage/ManageFooterContactLogoPage";
import { FooterContactLogoEditor } from "../components/LandingPage/FooterContactLogoEditor";
import { ManageFooterPartnerLogoPage } from "../components/LandingPage/ManageFooterPartnerLogoPage";
import { FooterPartnerLogoEditor } from "../components/LandingPage/FooterPartnerLogoEditor";
import { ManageServicesPageBlocksPage } from "../components/LandingPage/ManageServicesPageBlocksPage";
import { ServicesPageBlockEditor } from "../components/LandingPage/ServicesPageBlockEditor";
import { ContactPageInfoEditor } from "../components/LandingPage/ContactPageInfoEditor";
import { DatenschutzPageInfoEditor } from "../components/LandingPage/DatenschutzPageEditor";
import { ImpressumPageInfoEditor } from "../components/LandingPage/ImpressumPageInfoEditor";
import { BlogPostManager } from "../components/LandingPage/ManageBlogPost";
import { PageGeneralInfoEditor } from "../components/LandingPage/PageGeneralInfoEditor";
import {
  CreateNewBlogPost,
  EditSingleBlogPost,
} from "../components/LandingPage/SingleBlogPostEditor";
import { ManageServicesPageBlocksPage__WebDesign } from "../components/LandingPage/ManageServicesPageBlocksPage__WebDesign";
import { ServicesPageBlockEditor__WebDesign } from "../components/LandingPage/ServicesPageBlockEditor__WebDesign";
import { ManageServicesPageBlocksPage__SocialMedia } from "../components/LandingPage/ManageServicesPageBlocksPage__SocialMedia";
import { ServicesPageBlockEditor__SocialMedia } from "../components/LandingPage/ServicesPageBlockEditor__SocialMedia";
import { ManageServicesPageBlocksPage__NfcCard } from "../components/LandingPage/ManageServicesPageBlocksPage__NfcCard";
import { ServicesPageBlockEditor__NfcCard } from "../components/LandingPage/ServicesPageBlockEditor__NfcCard";
import { ManageServicesPageBlocksPage__GraphicDesign } from "../components/LandingPage/ManageServicesPageBlocksPage__GraphicDesign";
import { ServicesPageBlockEditor__GraphicDesign } from "../components/LandingPage/ServicesPageBlockEditor__GraphicDesign";

interface SingleRoute {
  name: string;
  path: string;
  index?: boolean;
  element: ReactNode;
  children?: SingleRoute[];
}

export const allRoutes: SingleRoute[] = [
  {
    name: "Home",
    path: "home",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Dashboard",
        path: "",
        element: <DashboardHome />,
        index: true,
      },
    ],
  },
  {
    name: "Edit",
    path: "edit",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Homepage Single Block",
        path: "homepage_singleblock",
        element: <HomePageBlockEditor />,
      },
    ],
  },
  {
    name: "Demo",
    path: "demo",
    element: <Outlet />,
    children: [
      {
        name: "Homepage Single Block Demo",
        path: "homepage_singleblock",
        element: <HomePageSingleBlockDemoPage />,
      },
      {
        name: "Cases Page Block Demo",
        path: "cases_page_singleblock",
        element: <CasesPageSingleBlockDemoPage />,
      },
      {
        name: "Top Video Demo",
        path: "topvideo",
        element: <TopVideoDemoPage />,
      },
    ],
  },
  {
    name: "Manage Homepage Blocks",
    path: "manage_homepage_blocks",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Overview",
        path: "",
        element: <ManageHomepageBlocksPage />,
        index: true,
      },
      {
        name: "Single",
        path: ":block_ID",
        element: <HomePageBlockEditor />,
      },
    ],
  },
  {
    name: "Manage Cases Page Blocks",
    path: "manage_cases_page_blocks",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Overview",
        path: "",
        element: <ManageCasesPageBlocksPage />,
        index: true,
      },
      {
        name: "Single",
        path: ":block_ID",
        element: <CasesPageBlockEditor />,
      },
    ],
  },
  {
    name: "Manage Homepage Partners",
    path: "manage_homepage_partners",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Overview",
        path: "",
        element: <HomepagePartnerSectionEditor />,
        index: true,
      },
    ],
  },
  {
    name: "Manage Blog Posts",
    path: "manage_blog_posts",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Overview",
        path: "",
        element: <BlogPostManager />,
        index: true,
      },
      {
        name: "Add",
        path: "add_more_post",
        element: <CreateNewBlogPost />,
      },
      {
        name: "Edit",
        path: ":post_ID",
        element: <EditSingleBlogPost />,
      },
    ],
  },
  {
    name: "Manage Top Video Blocks",
    path: "manage_topvideo_blocks",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Overview",
        path: "",
        element: <ManageTopVideosBlocksPage />,
        index: true,
      },
      {
        name: "Single",
        path: ":block_ID",
        element: <TopVideoEditor />,
      },
    ],
  },
  {
    name: "Manage Footer Contact Logos",
    path: "manage_footer_contact_logos",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Overview",
        path: "",
        element: <ManageFooterContactLogoPage />,
        index: true,
      },
      {
        name: "Single",
        path: ":block_ID",
        element: <FooterContactLogoEditor />,
      },
    ],
  },
  {
    name: "Contact Page Information",
    path: "edit_contact_page_information",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Edit",
        path: "",
        element: <ContactPageInfoEditor />,
        index: true,
      },
    ],
  },
  {
    name: "Datenschutz Page Information",
    path: "edit_datenschutz_page_information",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Edit",
        path: "",
        element: <DatenschutzPageInfoEditor />,
        index: true,
      },
    ],
  },
  {
    name: "Impressum Page Information",
    path: "edit_impressum_page_information",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Edit",
        path: "",
        element: <ImpressumPageInfoEditor />,
        index: true,
      },
    ],
  },
  {
    name: "Manage Footer Partner Logos",
    path: "manage_footer_partner_logos",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Overview",
        path: "",
        element: <ManageFooterPartnerLogoPage />,
        index: true,
      },
      {
        name: "Single",
        path: ":block_ID",
        element: <FooterPartnerLogoEditor />,
      },
    ],
  },
  {
    name: "Manage Services Page Block",
    path: "manage_services_page_blocks",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Overview",
        path: "",
        element: <ManageServicesPageBlocksPage />,
        index: true,
      },
      {
        name: "Single",
        path: ":block_ID",
        element: <ServicesPageBlockEditor />,
      },
    ],
  },
  {
    name: "Manage Block - Web Design",
    path: "manage_blocks_webdesign",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Overview",
        path: "",
        element: <ManageServicesPageBlocksPage__WebDesign />,
        index: true,
      },
      {
        name: "Single",
        path: ":block_ID",
        element: <ServicesPageBlockEditor__WebDesign />,
      },
    ],
  },
  {
    name: "Manage Block - Social Media",
    path: "manage_blocks_socialmedia",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Overview",
        path: "",
        element: <ManageServicesPageBlocksPage__SocialMedia />,
        index: true,
      },
      {
        name: "Single",
        path: ":block_ID",
        element: <ServicesPageBlockEditor__SocialMedia />,
      },
    ],
  },
  {
    name: "Manage Block - NFC Card",
    path: "manage_blocks_nfccard",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Overview",
        path: "",
        element: <ManageServicesPageBlocksPage__NfcCard />,
        index: true,
      },
      {
        name: "Single",
        path: ":block_ID",
        element: <ServicesPageBlockEditor__NfcCard />,
      },
    ],
  },
  {
    name: "Manage Block - Graphic Design",
    path: "manage_blocks_graphicdesign",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Overview",
        path: "",
        element: <ManageServicesPageBlocksPage__GraphicDesign />,
        index: true,
      },
      {
        name: "Single",
        path: ":block_ID",
        element: <ServicesPageBlockEditor__GraphicDesign />,
      },
    ],
  },
  {
    name: "Authentication",
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        name: "Sign In",
        path: "sign_in",
        element: <SignInPage />,
      },
    ],
  },
  {
    name: "Overall Information",
    path: "overall_information",
    element: <MyAdminLayout />,
    children: [
      {
        name: "Overview",
        path: "",
        element: <PageGeneralInfoEditor />,
        index: true,
      },
    ],
  },
];

export function childrenRenderer(children: SingleRoute[]) {
  if (children.length === 0) {
    return null;
  }
  return children.map((single, index) => (
    <Route key={index} path={single.path} element={single.element} />
  ));
}

export function findRouteIndex(children: SingleRoute[]) {
  if (children.length === 0) {
    return null;
  }
  const foundIndex = children.find((item) => item.index);
  if (!foundIndex) return null;

  return <Route index element={foundIndex.element} />;
}

export interface MyParams {
  postUrlName: string;
}
