import { Col, Row } from "react-bootstrap";
import { TableWithSelection } from "../UI_Components/TableWithSelection/TableWithSelection";
import { HeadCell } from "../../models/interface/tableUI.interface";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SingleBlogPostEntity } from "../../models/interface/single_blog_post.entity";
import { BlogPostState } from "../../store/reducers/blog_post_info.reducer";
import { Flex, FormLabel } from "@chakra-ui/react";
import { UI_Button } from "../UI_Components/UI_Button";

const head_cells: HeadCell[] = [
  {
    id: "ID",
    numeric: true,
    hidden: false,
    disablePadding: false,
    label: "ID",
    filter: false,
  },
  {
    id: "title",
    numeric: false,
    hidden: false,
    disablePadding: false,
    label: "Title",
    filter: true,
    clickable: true,
  },
  {
    id: "date",
    numeric: false,
    hidden: false,
    disablePadding: false,
    label: "Date",
    filter: true,
    clickable: false,
  },
];

interface TableBlogPostEntity extends SingleBlogPostEntity {
  title: string;
}

export const BlogPostManager = () => {
  const navigate = useNavigate();

  const blog_post_query = useQuery(gql_queries.all_blog_posts);

  const [tableData, setTableData] = useState<BlogPostState[] | null>(null);

  useEffect(() => {
    let data: any[] = blog_post_query.data?.all_blog_posts;
    if (blog_post_query.error || !data) {
      console.log(blog_post_query.error);
      return;
    }
    data = data.map((item) => {
      const copy: TableBlogPostEntity = { ...item };
      if (copy.date) {
        const d = new Date(copy.date);
        if (d.toLocaleString()) {
          copy.date = d.toLocaleString();
        }
      }
      copy.title = copy.de.title;
      return copy;
    });

    setTableData(data);
  }, [blog_post_query]);

  const refresh_table_fn = async () => {
    await blog_post_query.refetch();
    return;
  };

  const afterDeleteRowFn = async () => {
    return await blog_post_query.refetch();
  };

  const on_row_click = (value: any) => {
    navigate(`/manage_blog_posts/${value.ID}`);
  };

  return (
    <>
      {tableData ? (
        <Flex
          direction="column"
          pt={{ base: "120px", md: "75px" }}
          className="gap-3"
        >
          <FormLabel>Quản lý bài viết</FormLabel>
          <Row className="mb-3">
            <Col>
              <TableWithSelection
                table_title="Quản lý bài viết"
                headCells={head_cells}
                tableData={tableData}
                rowMainKey={"ID"}
                deleteRowApiUrlSuffix={"admin/delete_blog_post"}
                afterDeleteRowFn={afterDeleteRowFn}
                refresh_table_fn={refresh_table_fn}
                row_click_action={on_row_click}
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-end">
              <UI_Button
                variant="contained"
                color="success"
                onClick={() => navigate("/manage_blog_posts/add_more_post")}
              >
                <i className="fa-regular fa-plus me-2" />
                Thêm bài viết
              </UI_Button>
            </Col>
          </Row>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
};
