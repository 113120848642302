import { BgOuterBox } from "../FE_Common/LandingPage/BgOuterBox";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "../../purity/theme/theme";
import { PurityAuthNavbar } from "../UI_Components/Purity_Auth_Navbar";
import { VerticalLines } from "../FE_Common/LandingPage/verticallines/vertical_lines";
import React from "react";
import { DemoPageNavBarContent } from "./HomePageSingleBlockDemoPage";
import { CasesPageSingleBlock } from "../FE_Common/LandingPage/CasesPageSingleBlock";

export const CasesPageSingleBlockDemoPage = () => {
  const OuterBoxDraft = BgOuterBox({
    bg: "#f0f0f0",
    xlHeight: "20vh",
    largeHeight: "20vh",
    phoneHeight: "20vh",
    tabletHeight: "20vh",
  });

  return (
    <>
      <ChakraProvider theme={theme}>
        <PurityAuthNavbar content={<DemoPageNavBarContent />} />
      </ChakraProvider>
      <OuterBoxDraft>
        <VerticalLines bg="#212121" />
      </OuterBoxDraft>
      <CasesPageSingleBlock />
    </>
  );
};
