import { Flex, FormLabel, Text, useColorModeValue } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/models/corestore.interface";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import React, { useEffect, useRef, useState } from "react";
import Card from "../../purity/components/Card/Card";
import { Alert, CircularProgress } from "@mui/material";
import CardHeader from "../../purity/components/Card/CardHeader";
import CardBody from "../../purity/components/Card/CardBody";
import { Col, Row } from "react-bootstrap";
import { Chakra_Input } from "../UI_Components/Chakra_Input";
import { BlockEditorArrTypeActionButtons } from "./children/BlockEditorArrTypeActionButtons";
import { api_post_data } from "../../environments/api_handler";
import { cdnUrl } from "../../environments/environments";
import { UI_Button } from "../UI_Components/UI_Button";
import { AttachmentIcon } from "@chakra-ui/icons";
import { set_footer_partnerlogo_info } from "../../store/reducers/footer_partner_info.reducer";

const ChangeMediaCard = () => {
  const textColor = useColorModeValue("gray.700", "white");

  const BlockInfo = useSelector((e: AppState) => e.footer_partnerlogo_info);

  const dispatcher = useDispatch();

  const [is_waiting, set_is_waiting] = useState(false);

  async function upload_file(files: FileList) {
    set_is_waiting(true);
    const form_data = new FormData();
    form_data.append("overall_information_image", files[0]);
    await api_post_data("admin/upload_overall_information_image", form_data)
      .then((response) => {
        dispatcher(
          set_footer_partnerlogo_info({
            medias: {
              main_img: {
                ...BlockInfo.medias.main_img,
                url: response.data.payload.media_path,
              },
            },
          }),
        );
      })
      .catch((error) => console.log(error));
    set_is_waiting(false);
    return;
  }

  const addFileRef = useRef<any>(null);

  return (
    <Card p="16px">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Logo
        </Text>
      </CardHeader>
      <CardBody px="5px" className="flex-column">
        <Row className="justify-content-center">
          <Col xs="auto">
            {is_waiting ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <>
                <Row className="mb-3">
                  <Col>
                    <img
                      alt="Main image"
                      src={`${cdnUrl}/${BlockInfo.medias.main_img.url}`}
                      style={{
                        height: "auto",
                        width: 200,
                        borderRadius: "1rem",
                        objectFit: "cover",
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row className="justify-content-center">
              <Col xs="auto">
                <input
                  ref={addFileRef}
                  hidden
                  type="file"
                  name="upload"
                  onChange={async (e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      await upload_file(e.target.files);
                    }
                  }}
                />
                <UI_Button
                  color="secondary"
                  variant="contained"
                  onClick={() => addFileRef.current.click()}
                >
                  <span className="me-2">
                    <AttachmentIcon />
                  </span>
                  Thay đổi hình ảnh
                </UI_Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const ActionButtons = () => {
  const footer_partner_logos = useSelector(
    (e: AppState) => e.footer_partnerlogo_info,
  );

  return (
    <BlockEditorArrTypeActionButtons dataToSave={{ footer_partner_logos }} />
  );
};

export const FooterPartnerLogoEditor = () => {
  const textColor = useColorModeValue("gray.700", "white");

  const BlockInfo = useSelector((e: AppState) => e.footer_partnerlogo_info);

  const params = useParams();

  const dispatcher = useDispatch();

  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const data: any[] =
      overall_information_query.data?.overall_information?.footer_partner_logos;

    if (!data) return;

    const found = data.find((item: any) => item.ID == params.block_ID);
    if (!found) return;

    dispatcher(set_footer_partnerlogo_info(found));
  }, [overall_information_query.data, params]);

  return (
    <>
      <Flex
        direction="column"
        pt={{ base: "120px", md: "75px" }}
        className="gap-3"
      >
        <FormLabel>Chỉnh sửa partner logo</FormLabel>
        <Alert color="info">Bạn đang chỉnh sửa logo #{BlockInfo.ID}</Alert>
        <Row>
          <Col xs="12" md="6">
            <Card p="16px">
              <CardBody px="5px" className="flex-column">
                <FormLabel>Logo url:</FormLabel>
                <Chakra_Input
                  onChange={(newContent: string) => {
                    dispatcher(
                      set_footer_partnerlogo_info({
                        url: newContent,
                      }),
                    );
                  }}
                  value={BlockInfo.url}
                  placeholder="Đường dẫn đến trang partner..."
                />
                <ActionButtons />
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" md="6">
            <ChangeMediaCard />
          </Col>
        </Row>
      </Flex>
    </>
  );
};
