import {
  Box,
  Flex,
  HStack,
  Icon,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaGoogle } from "react-icons/fa";
import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { api_post_data } from "../../environments/api_handler";
import { toggleSnackbar } from "../UI_Components/UI_Snackbar";
import { useNavigate } from "react-router-dom";
import { toggleDialog } from "../UI_Components/UI_Dialog";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/reducers/userinfo.reducer";

export const loginSuccess = async (res: any) => {
  if (res.data && res.data.message) {
    toggleSnackbar.next(res.data.message);
    toggleDialog.next(false);
  }
  if (res.data && res.data.payload) {
    return res.data.payload;
  } else {
    toggleSnackbar.next("No access token received");
    return null;
  }
};

const GG_Login = () => {
  const dispatcher = useDispatch();
  const bgIcons = useColorModeValue("teal.200", "rgba(255, 255, 255, 0.5)");

  const gg_login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      responseGoogle(tokenResponse);
    },
  });

  const navigate = useNavigate();

  const responseGoogle = (response: any) => {
    const access_token = response?.access_token;
    if (access_token) {
      const loginInfo = { access_token };

      api_post_data("auth/google_login", loginInfo)
        .then(async (res) => {
          const success = await loginSuccess(res);
          if (success) {
            dispatcher(setUserInfo(success));
            navigate("/home");
          }
          return;
        })
        .catch((error) => console.log(error));
    } else {
      toggleSnackbar.next("Can not get GG access token, sign in by GG failed.");
    }
  };
  return (
    <Flex
      justify="center"
      align="center"
      w="75px"
      h="75px"
      borderRadius="15px"
      border="1px solid lightgray"
      cursor="pointer"
      transition="all .25s ease"
      _hover={{ filter: "brightness(300%)", bg: bgIcons }}
    >
      <Link onClick={() => gg_login()}>
        <Icon
          as={FaGoogle}
          w="30px"
          h="30px"
          _hover={{ filter: "brightness(120%)" }}
        />
      </Link>
    </Flex>
  );
};

export const SignInPage = () => {
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
    >
      <Box
        position="absolute"
        minH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        borderRadius={{ md: "15px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgImage="/BgSignUp.png"
        bgSize="cover"
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}
      ></Box>
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        align="center"
        mt="6.5rem"
        mb="30px"
      >
        <Text fontSize="4xl" color="white" fontWeight="bold">
          Welcome!
        </Text>
        <Text
          fontSize="md"
          color="white"
          fontWeight="normal"
          mt="10px"
          mb="26px"
          w={{ base: "90%", sm: "60%", lg: "40%", xl: "30%" }}
        >
          Use these awesome forms to login or create new account in your project
          for free.
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
        <Flex
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mx={{ base: "100px" }}
          bg={bgColor}
          boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
        >
          <Text
            fontSize="xl"
            color={textColor}
            fontWeight="bold"
            textAlign="center"
            mb="22px"
          >
            Sign In With
          </Text>
          <HStack spacing="15px" justify="center" mb="22px">
            <GG_Login />
          </HStack>
        </Flex>
      </Flex>
    </Flex>
  );
};
