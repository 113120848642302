import { NavLink, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import IconBox from "../../../purity/components/Icons/IconBox";
import { Separator } from "../../../purity/components/Separator/Separator";
import { MenuItem_Categories, MenuItems } from "../../../models/menuItems";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/models/corestore.interface";

export const CustomSidebarContent = (props: any) => {
  // to check for active links and opened collapses
  const location = useLocation();
  // this is for the rest of the collapses
  const [activeBg_light, set_activeBg_light] = useState<string>("white");
  const [inactiveBg_light, set_inactiveBg_light] = useState<string>("white");
  const [activeBg_dark, set_activeBg_dark] = useState<string>("gray.700");
  const [inactiveBg_dark, set_inactiveBg_dark] = useState<string>("gray.600");
  const [activeColor_light, set_activeColor_light] =
    useState<string>("gray.700");
  const [activeColor_dark, set_activeColor_dark] = useState<string>("white");

  const theme_options = useSelector(
    (e: AppState) => e.app_overall_info.theme_options_purity_admin_dashboard,
  );

  useEffect(() => {
    if (props.sidebarVariant === "opaque") {
      set_activeBg_light(theme_options.palette.primary.main);
      set_inactiveBg_light("white");
      set_activeBg_dark("gray.600");
      set_inactiveBg_dark("gray.600");
      set_activeColor_light("white");
      set_activeColor_dark("white");
    } else {
      set_activeBg_light("white");
      set_inactiveBg_light("gray.50");
      set_activeBg_dark("gray.600");
      set_inactiveBg_dark("gray.700");
      set_activeColor_light("gray.700");
      set_activeColor_dark("white");
    }
  }, [props.sidebarVariant]);

  const user_info = useSelector((e: AppState) => e.userInfo.data);

  const createLinks = () => {
    // Chakra Color Mode
    const activeBg = useColorModeValue(activeBg_light, activeBg_dark);
    const inactiveBg = useColorModeValue(inactiveBg_light, inactiveBg_dark);
    const activeTextColor = useColorModeValue(
      activeColor_light,
      activeColor_dark,
    );
    const inactiveTextColor = useColorModeValue("gray.400", "gray.400");
    const activeColor = useColorModeValue("gray.700", "white");
    const inactiveColor = useColorModeValue("gray.400", "gray.400");

    const MenuItems_noCat = MenuItems.filter((item) => !item.cat);

    return (
      <>
        {MenuItems_noCat.map((item, i) => (
          <React.Fragment key={i}>
            {item.include_roles.includes(user_info.user_role) &&
            !item.exclude_roles.includes(user_info.user_role) ? (
              <>
                <NavLink to={item.link}>
                  <Button
                    boxSize="initial"
                    justifyContent="flex-start"
                    alignItems="center"
                    bg={location.pathname == item.link ? activeBg : inactiveBg}
                    mb={{
                      xl: "12px",
                    }}
                    mx={{
                      xl: "auto",
                    }}
                    ps={{
                      sm: "10px",
                      xl: "16px",
                    }}
                    py="12px"
                    borderRadius="15px"
                    _hover={{}}
                    w="100%"
                    _active={{
                      bg: "inherit",
                      transform: "none",
                      borderColor: "transparent",
                    }}
                    _focus={{
                      boxShadow: "none",
                    }}
                  >
                    <Flex>
                      <IconBox
                        color={theme_options.palette.primary.main}
                        h="30px"
                        w="30px"
                        me="12px"
                      >
                        {item.icon ? item.icon : <QuestionOutlineIcon />}
                      </IconBox>
                      <Text
                        color={
                          location.pathname == item.link
                            ? activeTextColor
                            : inactiveTextColor
                        }
                        my="auto"
                        fontSize="sm"
                      >
                        {item.name}
                      </Text>
                    </Flex>
                  </Button>
                </NavLink>
              </>
            ) : (
              <></>
            )}
          </React.Fragment>
        ))}
        {MenuItem_Categories.sort((a, b) => b.order - a.order).map(
          (item, i) => (
            <React.Fragment key={i}>
              <Row className="align-items-center mb-3">
                <Col className="h-fit p-0" xs="auto">
                  <IconBox
                    bg={inactiveBg}
                    color="teal.300"
                    h="30px"
                    w="30px"
                    me="12px"
                  >
                    {item.icon ? item.icon : <QuestionOutlineIcon />}
                  </IconBox>
                </Col>
                <Col className="h-fit p-0">
                  <Text
                    color={activeColor}
                    fontWeight="bold"
                    mx="auto"
                    py="12px"
                  >
                    {item.name}
                  </Text>
                </Col>
              </Row>
              {MenuItems.filter((child_item) => child_item.cat == item.id).map(
                (single, i) => (
                  <React.Fragment key={i}>
                    {single.include_roles.includes(user_info.user_role) &&
                    !single.exclude_roles.includes(user_info.user_role) ? (
                      <>
                        <NavLink to={single.link}>
                          <Button
                            boxSize="initial"
                            justifyContent="flex-start"
                            alignItems="center"
                            bg={
                              location.pathname == single.link
                                ? activeBg
                                : inactiveBg
                            }
                            mb={{
                              xl: "12px",
                            }}
                            mx={{
                              xl: "auto",
                            }}
                            ps={{
                              sm: "10px",
                              xl: "16px",
                            }}
                            py="12px"
                            borderRadius="15px"
                            _hover={{}}
                            w="100%"
                            _active={{
                              bg: "inherit",
                              transform: "none",
                              borderColor: "transparent",
                            }}
                            _focus={{
                              boxShadow: "none",
                            }}
                          >
                            <Flex>
                              <Text
                                color={
                                  location.pathname == single.link
                                    ? activeTextColor
                                    : inactiveTextColor
                                }
                                my="auto"
                                fontSize="sm"
                              >
                                {single.name}
                              </Text>
                            </Flex>
                          </Button>
                        </NavLink>
                      </>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                ),
              )}
            </React.Fragment>
          ),
        )}
      </>
    );
  };

  const links = <>{createLinks()}</>;

  const navigate = useNavigate();

  return (
    <>
      <Box pt={"25px"} mb="12px">
        <Row className="mb-4">
          <Col className="d-flex align-items-center">
            <img
              src="/icons8-dashboard-100-b3h1j.png"
              className="me-2"
              width="32px"
              height="32px"
            />
            <Text
              className="c-pointer"
              onClick={() => navigate("/")}
              fontSize="sm"
              mt="3px"
              fontWeight="bold"
            >
              {props.logoText}
            </Text>
          </Col>
        </Row>
        <Separator></Separator>
      </Box>
      <Stack direction="column" mb="40px">
        <Box>{links}</Box>
      </Stack>
    </>
  );
};
