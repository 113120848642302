import { Box, useColorModeValue, useStyleConfig } from "@chakra-ui/react";
function MainPanel(props) {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig("MainPanel", { variant });
  // Pass the computed styles into the `__css` prop
  const PanelBg = useColorModeValue("gray.50", "gray.700")
  return (
    <Box bg={PanelBg} __css={styles} {...rest}>
      {children}
    </Box>
  );
}

export default MainPanel;
