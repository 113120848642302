import { Flex, FormLabel, Text, useColorModeValue } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/models/corestore.interface";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import React, { useEffect, useRef, useState } from "react";
import { HomePageSingleBlockMediaTypes } from "../../store/reducers/homepage_singleblock_info.reducer";
import { Alert, CircularProgress, Divider } from "@mui/material";
import Card from "../../purity/components/Card/Card";
import CardHeader from "../../purity/components/Card/CardHeader";
import CardBody from "../../purity/components/Card/CardBody";
import { Col, Row } from "react-bootstrap";
import { AppCoreLanguages } from "../../store/reducers/app_core_state.reducer";
import { Chakra_Selector } from "../UI_Components/Chakra_Selector";
import {
  set_topvideo_info,
  TopVideoBlockPositions,
  TopVideoMediaTypes,
  TopVideoState,
} from "../../store/reducers/topmedia_info.reducer";
import { Chakra_Input } from "../UI_Components/Chakra_Input";
import { toggleSnackbar } from "../UI_Components/UI_Snackbar";
import { UI_Button } from "../UI_Components/UI_Button";
import { api_post_data } from "../../environments/api_handler";
import { AttachmentIcon } from "@chakra-ui/icons";
import { cdnUrl } from "../../environments/environments";
import { BlockEditorArrTypeActionButtons } from "./children/BlockEditorArrTypeActionButtons";

const ActionButtons = () => {
  const top_videos = useSelector((e: AppState) => e.topvideo_info);

  return (
    <BlockEditorArrTypeActionButtons
      dataToSave={{ top_videos }}
      demoUrl="/demo/topvideo"
    />
  );
};

export function get_top_video_position_label(value: TopVideoBlockPositions) {
  switch (value) {
    case TopVideoBlockPositions.service__webdesign_page:
      return "Service - Web Design";
    case TopVideoBlockPositions.service__socialmedia_page:
      return "Service - Social Media";
    case TopVideoBlockPositions.service__graphic_design_page:
      return "Service - Graphic Design";
    case TopVideoBlockPositions.service__nfc_card_page:
      return "Service - Smart Card";
    case TopVideoBlockPositions.homepage:
      return "Trang chủ";
    case TopVideoBlockPositions.cases_page:
      return "Trang cases";
    case TopVideoBlockPositions.services_page:
      return "Trang dịch vụ";
    case TopVideoBlockPositions.contact_page:
      return "Trang contact";
    case TopVideoBlockPositions.blog_page:
      return "Trang Blog";
    case TopVideoBlockPositions.impressum_page:
      return "Trang Impressum";
    case TopVideoBlockPositions.privacy_page:
      return "Trang Datenschutz";
    case TopVideoBlockPositions.notfound_page:
      return "Trang 404";
  }
  return "";
}

const BlockPositionSelector = ({
  all_video_blocks,
}: {
  all_video_blocks: TopVideoState[];
}) => {
  const BlockInfo = useSelector((e: AppState) => e.topvideo_info);

  const data_set = Object.keys(TopVideoBlockPositions).map((item: any) => {
    return {
      value: item,
      label: get_top_video_position_label(item),
    };
  });

  const check_duplicate = (value: string) => {
    const found = all_video_blocks.find((item) => item.block_position == value);
    return !!found;
  };

  const dispatcher = useDispatch();

  function myChangeEvent(e: any) {
    const is_duplicate = check_duplicate(e.target.value);
    if (is_duplicate) {
      toggleSnackbar.next("Vị trí này đã có block khác, vui lòng chọn lại");
      return;
    }
    dispatcher(set_topvideo_info({ block_position: e.target.value }));
  }

  return (
    <>
      <FormLabel>Vị trí của block</FormLabel>
      <Chakra_Selector
        value={BlockInfo.block_position}
        dataSet={data_set}
        onChange={myChangeEvent}
        placeholder="Vị trí của block"
      />
    </>
  );
};

const ChangeContentBlock = ({
  lang,
  title,
}: {
  lang: AppCoreLanguages;
  title: string;
}) => {
  const textColor = useColorModeValue("gray.700", "white");

  const BlockInfo = useSelector((e: AppState) => e.topvideo_info);

  const dispatcher = useDispatch();

  return (
    <Card p="16px">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          {title}
        </Text>
      </CardHeader>
      <CardBody px="5px" className="flex-column">
        <Row>
          <Col xs="12" md="4">
            <FormLabel>Tiêu đề phía trên</FormLabel>
            <Chakra_Input
              onChange={(newContent: string) => {
                dispatcher(
                  set_topvideo_info({
                    [lang]: {
                      ...BlockInfo[lang],
                      title_topleft: newContent,
                    },
                  }),
                );
              }}
              value={BlockInfo[lang].title_topleft}
              placeholder="Tiêu đề phía trên"
            />
          </Col>
          <Col xs="12" md="4">
            <FormLabel>{`Tiêu đề chính giữa (max. 8 ký tự)`}</FormLabel>
            <Chakra_Input
              maxLength={8}
              onChange={(newContent: string) => {
                dispatcher(
                  set_topvideo_info({
                    [lang]: {
                      ...BlockInfo[lang],
                      main_text: newContent,
                    },
                  }),
                );
              }}
              value={BlockInfo[lang].main_text}
              placeholder="Tiêu đề chính giữa"
            />
          </Col>
          <Col xs="12" md="4">
            <FormLabel>Tiêu đề phía dưới</FormLabel>
            <Chakra_Input
              onChange={(newContent: string) => {
                dispatcher(
                  set_topvideo_info({
                    [lang]: {
                      ...BlockInfo[lang],
                      title_bottomleft: newContent,
                    },
                  }),
                );
              }}
              value={BlockInfo[lang].title_bottomleft}
              placeholder="Tiêu đề phía dưới"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <ActionButtons />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const BlockOptionsCard = ({
  all_video_blocks,
}: {
  all_video_blocks: TopVideoState[];
}) => {
  const textColor = useColorModeValue("gray.700", "white");

  const BlockInfo = useSelector((e: AppState) => e.topvideo_info);

  const dispatcher = useDispatch();

  return (
    <Card p="16px">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Tùy chọn
        </Text>
      </CardHeader>
      <CardBody px="5px" className="flex-column">
        <Row className="w-100">
          <Col xs="12" md="4">
            <BlockPositionSelector all_video_blocks={all_video_blocks} />
          </Col>
          <Col xs="12" md="4">
            <FormLabel>Link bên trái</FormLabel>
            <Chakra_Input
              onChange={(newContent: string) => {
                dispatcher(
                  set_topvideo_info({
                    prev_link: newContent,
                  }),
                );
              }}
              value={BlockInfo.prev_link}
              placeholder="Link bên trái"
            />
          </Col>
          <Col xs="12" md="4">
            <FormLabel>Link bên phải</FormLabel>
            <Chakra_Input
              onChange={(newContent: string) => {
                dispatcher(
                  set_topvideo_info({
                    next_link: newContent,
                  }),
                );
              }}
              value={BlockInfo.next_link}
              placeholder="Link bên phải"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <ActionButtons />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const ChangeMediaCard = () => {
  const textColor = useColorModeValue("gray.700", "white");

  const BlockInfo = useSelector((e: AppState) => e.topvideo_info);

  const media_type_dataSet = Object.keys(HomePageSingleBlockMediaTypes).map(
    (item) => {
      return {
        value: item,
        label: item,
      };
    },
  );
  const dispatcher = useDispatch();

  const [is_waiting, set_is_waiting] = useState(false);

  async function upload_file(files: FileList) {
    set_is_waiting(true);
    const form_data = new FormData();
    form_data.append("overall_information_image", files[0]);
    await api_post_data("admin/upload_overall_information_image", form_data)
      .then((response) => {
        dispatcher(
          set_topvideo_info({
            medias: {
              ...BlockInfo.medias,
              main_img: {
                ...BlockInfo.medias.main_img,
                url: response.data.payload.media_path,
              },
            },
          }),
        );
      })
      .catch((error) => console.log(error));
    set_is_waiting(false);
    return;
  }

  const addFileRef = useRef<any>(null);

  return (
    <Card p="16px">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Hình ảnh
        </Text>
      </CardHeader>
      <CardBody px="5px" className="flex-column">
        <Row className="w-100">
          <Col xs="12" md="8" className="mb-4 mb-md-0">
            <Row>
              <Col>
                <FormLabel>Loại tệp</FormLabel>
                <Chakra_Selector
                  value={BlockInfo.medias.main_img.type}
                  dataSet={media_type_dataSet}
                  onChange={(e) =>
                    dispatcher(
                      set_topvideo_info({
                        medias: {
                          ...BlockInfo.medias,
                          main_img: {
                            ...BlockInfo.medias.main_img,
                            type: e.target.value,
                          },
                        },
                      }),
                    )
                  }
                  placeholder="Loại tệp"
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto">
                <ActionButtons />
              </Col>
            </Row>
          </Col>
          <Col xs="12" md="4" className="text-center">
            {is_waiting ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <>
                <Row className="mb-3">
                  <Col>
                    {BlockInfo.medias.main_img.type ==
                    TopVideoMediaTypes.image ? (
                      <img
                        src={`${cdnUrl}/${BlockInfo.medias.main_img.url}`}
                        style={{
                          minHeight: 200,
                          width: "auto",
                          borderRadius: "1rem",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <video
                        autoPlay
                        loop
                        preload="metadata"
                        playsInline
                        muted
                        style={{
                          minHeight: 200,
                          width: "auto",
                          objectFit: "contain",
                          borderRadius: "1rem",
                        }}
                        data-hd="true"
                      >
                        <source
                          src={`${cdnUrl}/${BlockInfo.medias.main_img.url}`}
                          type="video/mp4"
                        />
                        {`Your browser doesn't support HTML5 video tag.`}
                      </video>
                    )}
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col>
                <input
                  ref={addFileRef}
                  hidden
                  type="file"
                  name="upload"
                  onChange={async (e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      await upload_file(e.target.files);
                    }
                  }}
                />
                <UI_Button
                  color="secondary"
                  variant="contained"
                  onClick={() => addFileRef.current.click()}
                >
                  <span className="me-2">
                    <AttachmentIcon />
                  </span>
                  Thay đổi hình ảnh
                </UI_Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export const TopVideoEditor = () => {
  const BlockInfo = useSelector((e: AppState) => e.topvideo_info);

  const params = useParams();

  const dispatcher = useDispatch();

  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  const [all_video_blocks_without_this, set_all_video_blocks_without_this] =
    useState<TopVideoState[]>([]);

  useEffect(() => {
    const data: any[] =
      overall_information_query.data?.overall_information?.top_videos;

    if (!data) return;

    set_all_video_blocks_without_this(
      data.filter((item: TopVideoState) => item.ID != params.block_ID),
    );

    const found = data.find((item: any) => item.ID == params.block_ID);
    if (!found) return;

    if (BlockInfo.ID != found.ID) dispatcher(set_topvideo_info(found));
  }, [overall_information_query.data, params]);

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      className="gap-3"
    >
      <FormLabel>Quản lý top videos</FormLabel>
      <Alert color="info">Bạn đang chỉnh sửa block #{BlockInfo.ID}</Alert>
      <BlockOptionsCard all_video_blocks={all_video_blocks_without_this} />
      <Row className="py-4">
        <Col>
          <Divider>Chỉnh sửa nội dung</Divider>
        </Col>
      </Row>
      <ChangeContentBlock
        lang={AppCoreLanguages.de}
        title="Nội dung tiếng Đức"
      />
      <ChangeContentBlock
        lang={AppCoreLanguages.en}
        title="Nội dung tiếng Anh"
      />
      <ChangeContentBlock
        lang={AppCoreLanguages.vi}
        title="Nội dung tiếng Việt"
      />
      <ChangeContentBlock lang={AppCoreLanguages.it} title="Nội dung tiếng Ý" />
      <ChangeMediaCard />
    </Flex>
  );
};
