import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import CardHeader from "../../purity/components/Card/CardHeader";
import CardBody from "../../purity/components/Card/CardBody";
import Card from "../../purity/components/Card/Card";
import React, { useEffect, useState } from "react";
import { QueryResult, useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import { useNavigate } from "react-router-dom";
import { UI_Button } from "../UI_Components/UI_Button";
import { api_post_data } from "../../environments/api_handler";
import { AddIcon } from "@chakra-ui/icons";
import { Col, Row } from "react-bootstrap";
import { Typography } from "@mui/material";
import {
  footer_contactlogo_initial_state,
  FooterContactLogoState,
} from "../../store/reducers/footer_contact_logo_info.reducer";
import { v4 } from "uuid";

const SingleBlockRows = ({
  overall_information_query,
}: {
  overall_information_query: QueryResult;
}) => {
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("#F8F9FA", "gray.800");
  const nameColor = useColorModeValue("gray.500", "white");

  const [contact_logo_blocks, set_contact_logo_blocks] = useState<
    FooterContactLogoState[]
  >([]);

  useEffect(() => {
    const data = overall_information_query.data?.overall_information;

    if (data && data.footer_contact_logos) {
      set_contact_logo_blocks(data.footer_contact_logos);
    }
  }, [overall_information_query.data]);

  const navigate = useNavigate();

  return (
    <>
      {contact_logo_blocks.map((item, i) => (
        <Box key={i} p="24px" bg={bgColor} my="22px" borderRadius="12px">
          <Flex justify="space-between" w="100%">
            <Flex
              direction="column"
              maxWidth="70%"
              justifyContent="space-between"
            >
              <Typography variant="h4">
                <i className={`fa-brands fa-${item.logo}`} />
              </Typography>
              <Text color={textColor} fontSize="sm">
                <strong>ID:</strong> {item.ID.substring(0, 10)}...
              </Text>
            </Flex>
            <Flex
              direction={{ sm: "column", md: "row" }}
              align="flex-start"
              p={{ md: "24px" }}
            >
              <UI_Button
                color="error"
                className="mx-1"
                onClick={async () => {
                  if (!window.confirm("Are you sure?")) return;
                  await api_post_data(
                    "admin/delete_item_overall_information_arr_type",
                    {
                      name: "footer_contact_logos",
                      ID: item.ID,
                    },
                  );
                  await overall_information_query.refetch();
                }}
              >
                <Typography variant="body1">
                  <span className="me-2">
                    <i className="fa-light fa-trash-can" />
                  </span>
                  Xóa
                </Typography>
              </UI_Button>
              <UI_Button
                color="primary"
                className="mx-1"
                onClick={() =>
                  navigate(`/manage_footer_contact_logos/${item.ID}`)
                }
              >
                <Typography variant="body1">
                  <span className="me-2">
                    <i className="fa-light fa-pen" />
                  </span>
                  Sửa
                </Typography>
              </UI_Button>
            </Flex>
          </Flex>
        </Box>
      ))}
    </>
  );
};

export const ManageFooterContactLogoPage = () => {
  const textColor = useColorModeValue("gray.700", "white");

  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      className="gap-3"
    >
      <Card my={{ lg: "24px" }} me={{ lg: "24px" }}>
        <Flex direction="column">
          <CardHeader py="12px">
            <Row className="justify-content-between w-100 align-items-center">
              <Col xs="auto" className="h-fit">
                <Text
                  color={textColor}
                  me="1rem"
                  fontSize="lg"
                  fontWeight="bold"
                >
                  Quản lý Logos phần Contact
                </Text>
              </Col>
              <Col xs="auto" className="h-fit">
                <UI_Button
                  color="success"
                  onClick={async () => {
                    const footer_contact_logos: FooterContactLogoState = {
                      ID: v4(),
                      logo: footer_contactlogo_initial_state.logo,
                      url: footer_contactlogo_initial_state.url,
                    };
                    await api_post_data(
                      "admin/edit_overall_information_arr_type",
                      { footer_contact_logos },
                    );
                    await overall_information_query.refetch();
                  }}
                >
                  <AddIcon className="me-2" /> Thêm logo
                </UI_Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Flex direction="column" w="100%">
              <SingleBlockRows
                overall_information_query={overall_information_query}
              />
            </Flex>
          </CardBody>
        </Flex>
      </Card>
    </Flex>
  );
};
