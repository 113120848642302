import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import {
  ChakraProvider,
  ColorModeProvider,
  CSSReset,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import theme from "../../purity/theme/theme";
import routes from "../../purity/routes";
import MainPanel from "../../purity/components/Layout/MainPanel";
import PanelContent from "../../purity/components/Layout/PanelContent";
import PanelContainer from "../../purity/components/Layout/PanelContainer";
import FixedPlugin from "../../purity/components/FixedPlugin/FixedPlugin";
import { CustomAdminNavBar } from "./children/CustomAdminNavBar";
import { CustomAdminSidebar } from "./children/CustomAdminSidebar";
import { PurityCustomConfigurator } from "../LandingPage/children/CustomPurityConfiguration";

export const MyAdminLayout = (props: any) => {
  const { ...rest } = props;
  // states and functions
  const [sidebarVariant, setSidebarVariant] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  // functions for changing the states from components
  const getActiveRoute = (routes: any) => {
    const activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        const collapseActiveRoute: any = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        const categoryActiveRoute: any = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  // This changes navbar state(fixed or not)
  const getActiveNavbar = (routes: any) => {
    const activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        const categoryActiveNavbar: any = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = "ltr";
  // Chakra Color Mode
  return (
    <ChakraProvider theme={theme}>
      <ColorModeProvider>
        <CSSReset />
        <CustomAdminSidebar
          routes={routes}
          logoText={"DASHBOARD"}
          display="none"
          sidebarVariant={sidebarVariant}
          {...rest}
        />
        <MainPanel
          minHeight="100vh"
          w={{
            base: "100%",
            xl: "calc(100% - 275px)",
          }}
        >
          <Portal>
            <CustomAdminNavBar
              onOpen={onOpen}
              logoText={"PURITY UI DASHBOARD"}
              brandText={getActiveRoute(routes)}
              secondary={getActiveNavbar(routes)}
              fixed={fixed}
              {...rest}
            />
          </Portal>
          <PanelContent>
            <PanelContainer>
              <Outlet />
            </PanelContainer>
          </PanelContent>
          <Portal>
            <FixedPlugin
              secondary={getActiveNavbar(routes)}
              fixed={fixed}
              onOpen={onOpen}
            />
          </Portal>
          <PurityCustomConfigurator
            secondary={getActiveNavbar(routes)}
            isOpen={isOpen}
            onClose={onClose}
            isChecked={fixed}
            onSwitch={(value: any) => {
              setFixed(value);
            }}
            onOpaque={() => setSidebarVariant("opaque")}
            onTransparent={() => setSidebarVariant("transparent")}
          />
        </MainPanel>
      </ColorModeProvider>
    </ChakraProvider>
  );
};
