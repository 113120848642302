import { ChakraProvider, ColorModeProvider, Input } from "@chakra-ui/react";
import React from "react";
import theme from "../../purity/theme/theme";

export const Chakra_Input = ({
  onChange,
  onBlur,
  value,
  placeholder,
  type,
  size,
  error,
  maxLength,
}: {
  onChange: (newContent: any) => any;
  onBlur?: (newContent: any) => any;
  value?: string | number;
  placeholder?: string;
  type?: "text" | "number";
  size?: string;
  error?: boolean;
  maxLength?: number;
}) => {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeProvider>
        <Input
          isInvalid={error}
          type={type ? type : "text"}
          onChange={(e) => onChange(e.target.value)}
          onBlur={(e) => (onBlur ? onBlur(e.target.value) : 2)}
          value={value}
          focusBorderColor="#4FD1C5"
          borderRadius="15px"
          mb="24px"
          fontSize="sm"
          placeholder={placeholder ? placeholder : ""}
          size={size ? size : "lg"}
          maxLength={maxLength}
        />
      </ColorModeProvider>
    </ChakraProvider>
  );
};
