import { Alert, Box, Breadcrumbs, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { allRoutes } from "../../models/allRoutes";
import { Link, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export const toggleBreadcrumbs$ = new Subject();

export const MyBreadCrumb = (props: React.ComponentProps<any>) => {
  const location = useLocation();

  const [is_open, set_is_open] = useState(true);

  const {
    start_seite_link,
    start_seite_name,
    is_dynamic,
    search_source,
    search_key,
    search_value,
    dynamic_path_name,
  } = props;

  if (is_dynamic && (!search_key || !search_value || !dynamic_path_name)) {
    return (
      <Alert severity="info">
        Breadcrumbs is not working for this page, dynamic path error.
      </Alert>
    );
  }

  let destroy$: any;

  useEffect(() => {
    destroy$ = new Subject();

    toggleBreadcrumbs$
      .pipe(takeUntil(destroy$))
      .subscribe((res: any) => set_is_open(res));

    return () => {
      destroy$.next(false);
      destroy$.unsubscribe();
    };
  }, []);

  const MyLink = styled(Link)`
    text-decoration: none;
    opacity: 0.7;
  `;

  let CurrentPosition: any = null;

  const getBreadCrumbs = () => {
    const allCrumbs = location.pathname.substring(1).split("/");
    if (allCrumbs.length > 2 || allCrumbs.length === 0) {
      return (
        <Alert severity="info">
          Breadcrumbs is not working for this page, too many path children.
        </Alert>
      );
    }

    const firstItem = allRoutes.find((item) => item.path == allCrumbs[0]);
    if (!firstItem) {
      return (
        <Alert severity="info">
          Breadcrumbs is not working for this page, first item not found.
        </Alert>
      );
    }

    let FirstLink = MyLink;

    if (allCrumbs.length == 1) {
      FirstLink = styled(MyLink)`
        opacity: 1;
      `;
    }

    const NewBreadcrumb = (
      <FirstLink color="inherit" to={`/${firstItem.path}`}>
        <Button>{firstItem.name}</Button>
      </FirstLink>
    );

    let secondItem;

    if (firstItem.children && firstItem.children.length > 0 && allCrumbs[1]) {
      secondItem = firstItem.children.find((item) => item.path == allCrumbs[1]);
      if (secondItem) {
        CurrentPosition = <Button>{secondItem.name}</Button>;
      }
      if (
        search_value &&
        search_source &&
        search_key &&
        dynamic_path_name &&
        !secondItem
      ) {
        const found_item = search_source?.find(
          (item: any) => item[search_key] == search_value,
        );
        if (found_item) CurrentPosition = <Button>{dynamic_path_name}</Button>;
      }
    }

    return (
      <Breadcrumbs aria-label="breadcrumb">
        <MyLink to={start_seite_link ? start_seite_link : "/"}>
          <Button>{start_seite_name ? start_seite_name : "Startseite"}</Button>
        </MyLink>
        {NewBreadcrumb}
        {CurrentPosition}
      </Breadcrumbs>
    );
  };

  return (
    <Box
      style={{
        display: is_open ? "block" : "none",
      }}
    >
      {getBreadCrumbs()}
    </Box>
  );
};

MyBreadCrumb.propTypes = {
  start_seite_link: PropTypes.string,
  start_seite_name: PropTypes.string,
  is_dynamic: PropTypes.bool.isRequired,
  search_source: PropTypes.any,
  search_key: PropTypes.string,
  search_value: PropTypes.any,
  dynamic_path_name: PropTypes.string,
};
