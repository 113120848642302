import React, { useEffect, useRef, useState } from "react";
import {
  homepage_partner_section_media_key_array,
  HomepagePartnerSectionMediaTypes,
  HomepagePartnerSectionState,
  set_homepage_partner_section_info,
} from "../../store/reducers/homepage_partner_section_info.reducer";
import { useDispatch, useSelector } from "react-redux";
import { Flex, FormLabel } from "@chakra-ui/react";
import CardBody from "../../purity/components/Card/CardBody";
import { Col, Row } from "react-bootstrap";
import Card from "../../purity/components/Card/Card";
import { api_post_data } from "../../environments/api_handler";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { cdnUrl } from "../../environments/environments";
import { UI_Button } from "../UI_Components/UI_Button";
import { AttachmentIcon, CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import { AppState } from "../../store/models/corestore.interface";
import { QueryResult, useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import { useNavigate } from "react-router-dom";
import { Chakra_Selector } from "../UI_Components/Chakra_Selector";

const ActionButtons = () => {
  const navigate = useNavigate();

  const BlockInfo = useSelector(
    (e: AppState) => e.homepage_partner_section_info,
  );

  return (
    <Row>
      <Col xs="auto">
        <UI_Button
          color="warning"
          variant="contained"
          onClick={() => navigate(-1)}
        >
          <span>
            <i className="fa-regular fa-left-long" />
          </span>
        </UI_Button>
      </Col>
      <Col xs="auto">
        <UI_Button
          color="success"
          variant="contained"
          onClick={async () => {
            await api_post_data("admin/edit_overall_information", {
              homepage_partner_section: BlockInfo,
            });
            return;
          }}
        >
          <CheckIcon className="me-2" />
          <span>Lưu</span>
        </UI_Button>
      </Col>
    </Row>
  );
};

const UploadPartnerLogoCard = ({
  img_key,
  overall_information_query,
}: {
  img_key: string;
  overall_information_query: QueryResult;
}) => {
  if (!homepage_partner_section_media_key_array().includes(img_key)) {
    return <></>;
  }

  const BlockInfo = useSelector(
    (e: AppState) => e.homepage_partner_section_info,
  );

  const dispatcher = useDispatch();

  const [is_waiting, set_is_waiting] = useState(false);

  async function upload_file(files: FileList) {
    set_is_waiting(true);
    const form_data = new FormData();
    form_data.append("overall_information_image", files[0]);
    await api_post_data("admin/upload_overall_information_image", form_data)
      .then((response) => {
        dispatcher(
          set_homepage_partner_section_info({
            medias: {
              ...BlockInfo.medias,
              [img_key]: {
                ...BlockInfo.medias[img_key],
                url: response.data.payload.media_path,
              },
            },
          }),
        );
      })
      .catch((error) => console.log(error));
    set_is_waiting(false);
    return;
  }

  async function delete_logo_item() {
    const init_url = BlockInfo.medias[img_key].url;
    set_homepage_partner_section_info({
      medias: {
        ...BlockInfo.medias,
        [img_key]: {
          ...BlockInfo.medias[img_key],
          url: "",
        },
      },
    });
    const newValue: HomepagePartnerSectionState = {
      ID: BlockInfo.ID,
      medias: {
        ...BlockInfo.medias,
        [img_key]: {
          ...BlockInfo.medias[img_key],
          url: "",
        },
      },
    };
    await api_post_data("admin/edit_overall_information", {
      homepage_partner_section: newValue,
    });
    await api_post_data("admin/turn_media_to_trash_by_media_path", {
      media_path: init_url,
    });
    await overall_information_query.refetch();
  }

  const addFileRef = useRef<any>(null);
  return (
    <>
      <Card mb="20px">
        <CardBody px="5px" className="flex-column">
          {is_waiting ? (
            <>
              <CircularProgress />
            </>
          ) : (
            <>
              <Row className="mb-3 justify-content-center">
                <Col xs="auto">
                  {BlockInfo.medias[img_key].url ? (
                    <>
                      {BlockInfo.medias[img_key].type ==
                      HomepagePartnerSectionMediaTypes.image ? (
                        <Box
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            width: 250,
                            height: 250,
                            background: "#000",
                            borderRadius: "1rem",
                          }}
                        >
                          <img
                            alt="Partner image"
                            src={`${cdnUrl}/${BlockInfo.medias[img_key].url}`}
                            style={{
                              height: "auto",
                              maxWidth: 150,
                            }}
                          />
                        </Box>
                      ) : (
                        <video
                          autoPlay
                          loop
                          preload="metadata"
                          playsInline
                          muted
                          style={{
                            minHeight: 200,
                            width: "auto",
                            objectFit: "contain",
                            borderRadius: "1rem",
                          }}
                          data-hd="true"
                        >
                          <source
                            src={`${cdnUrl}/${BlockInfo.medias[img_key].url}`}
                            type="video/mp4"
                          />
                          {`Your browser doesn't support HTML5 video tag.`}
                        </video>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography>Chưa thiết lập</Typography>
                    </>
                  )}
                </Col>
              </Row>
            </>
          )}
          <Row className="justify-content-center">
            <Col xs="auto">
              <input
                ref={addFileRef}
                hidden
                type="file"
                name="upload"
                onChange={async (e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    await upload_file(e.target.files);
                  }
                }}
              />
              <UI_Button
                color="secondary"
                variant="contained"
                onClick={() => addFileRef.current.click()}
              >
                <span className="me-2">
                  <AttachmentIcon />
                </span>
                Tải lên
              </UI_Button>
            </Col>
            {BlockInfo.medias[img_key].url ? (
              <Col xs="auto">
                <UI_Button
                  color="error"
                  variant="contained"
                  onClick={() => delete_logo_item()}
                >
                  <span className="me-2">
                    <DeleteIcon />
                  </span>
                  Xóa
                </UI_Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

const UploadPartnerBgCard = () => {
  const [is_waiting, set_is_waiting] = useState(false);

  const BlockInfo = useSelector(
    (e: AppState) => e.homepage_partner_section_info,
  );

  const addFileRef = useRef<any>(null);

  const dispatcher = useDispatch();

  async function upload_file(files: FileList) {
    set_is_waiting(true);
    const form_data = new FormData();
    form_data.append("overall_information_image", files[0]);
    await api_post_data("admin/upload_overall_information_image", form_data)
      .then((response) => {
        dispatcher(
          set_homepage_partner_section_info({
            medias: {
              ...BlockInfo.medias,
              main_img: {
                ...BlockInfo.medias.main_img,
                url: response.data.payload.media_path,
              },
            },
          }),
        );
      })
      .catch((error) => console.log(error));
    set_is_waiting(false);
    return;
  }

  const media_type_dataSet = Object.keys(HomepagePartnerSectionMediaTypes).map(
    (item) => {
      return {
        value: item,
        label: item,
      };
    },
  );

  return (
    <>
      <Card mb="20px">
        <CardBody px="5px" className="flex-column">
          {is_waiting ? (
            <>
              <CircularProgress />
            </>
          ) : (
            <>
              <Row className="mb-3 justify-content-center">
                <Col xs="auto">
                  {BlockInfo.medias.main_img.url ? (
                    <>
                      {BlockInfo.medias.main_img.type ==
                      HomepagePartnerSectionMediaTypes.image ? (
                        <img
                          alt="Partner background"
                          src={`${cdnUrl}/${BlockInfo.medias.main_img.url}`}
                          style={{
                            width: "100%",
                            height: "auto",
                            background: "#000",
                            borderRadius: "1rem",
                          }}
                        />
                      ) : (
                        <video
                          autoPlay
                          loop
                          preload="metadata"
                          playsInline
                          muted
                          style={{
                            minHeight: 200,
                            width: "auto",
                            objectFit: "contain",
                            borderRadius: "1rem",
                          }}
                          data-hd="true"
                        >
                          <source
                            src={`${cdnUrl}/${BlockInfo.medias.main_img.url}`}
                            type="video/mp4"
                          />
                          {`Your browser doesn't support HTML5 video tag.`}
                        </video>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography>Chưa thiết lập</Typography>
                    </>
                  )}
                </Col>
              </Row>
            </>
          )}
          <Row className="justify-content-center justify-content-md-around align-items-center">
            <Col className="h-fit" xs="12" md="auto">
              <FormLabel>Loại tệp</FormLabel>
              <Chakra_Selector
                value={BlockInfo.medias.main_img.type}
                dataSet={media_type_dataSet}
                onChange={(e) =>
                  dispatcher(
                    set_homepage_partner_section_info({
                      medias: {
                        ...BlockInfo.medias,
                        main_img: {
                          ...BlockInfo.medias.main_img,
                          type: e.target.value,
                        },
                      },
                    }),
                  )
                }
                placeholder="Loại tệp"
              />
            </Col>
            <Col className="h-fit" xs="auto">
              <input
                ref={addFileRef}
                hidden
                type="file"
                name="upload"
                onChange={async (e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    await upload_file(e.target.files);
                  }
                }}
              />
              <UI_Button
                color="secondary"
                variant="contained"
                onClick={() => addFileRef.current.click()}
              >
                <span className="me-2">
                  <AttachmentIcon />
                </span>
                Tải lên
              </UI_Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export const HomepagePartnerSectionEditor = () => {
  const dispatcher = useDispatch();

  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const data: HomepagePartnerSectionState =
      overall_information_query.data?.overall_information
        ?.homepage_partner_section;

    if (!data) return;

    dispatcher(set_homepage_partner_section_info(data));
  }, [overall_information_query]);

  return (
    <>
      <Flex
        direction="column"
        pt={{ base: "120px", md: "75px" }}
        className="gap-3"
      >
        <FormLabel>Các partners trên trang chủ</FormLabel>
        <UploadPartnerBgCard />
        <Row className="justify-content-end">
          <Col xs="auto">
            <ActionButtons />
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            <Divider>Partner logos</Divider>
          </Col>
        </Row>
        <Row>
          {homepage_partner_section_media_key_array().map((item, i) => (
            <Col key={i} xs="12" sm="6" lg="4">
              <UploadPartnerLogoCard
                overall_information_query={overall_information_query}
                img_key={item}
              />
            </Col>
          ))}
        </Row>
      </Flex>
    </>
  );
};
