import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/models/corestore.interface";
import { Box, TextField, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import {
  BlogPostCategoryState,
  reset_blog_post_category_state,
  set_blog_post_category_info,
} from "../../../store/reducers/blog_post_category.reducer";
import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../../models/interface/gql_queries";
import { api_post_data } from "../../../environments/api_handler";
import { dialogContent, toggleDialog } from "../../UI_Components/UI_Dialog";
import { UI_Button } from "../../UI_Components/UI_Button";
import { set_blog_post_info } from "../../../store/reducers/blog_post_info.reducer";
import { FormLabel } from "@chakra-ui/react";
import { Chakra_Selector } from "../../UI_Components/Chakra_Selector";

export const AddCategoryButton = () => {
  return (
    <UI_Button
      variant="contained"
      onClick={() => {
        toggleDialog.next(true);
        dialogContent.next(<AddBlogPostCategoryPanel />);
      }}
    >
      <i className="fa-regular fa-plus me-2" />
      Category
    </UI_Button>
  );
};

export const AddBlogPostCategoryPanel = () => {
  const category_query = useQuery(gql_queries.all_blog_post_categories, {
    fetchPolicy: "cache-and-network",
  });
  const category_info = useSelector((e: AppState) => e.blog_post_category);

  useEffect(() => {
    dispatcher(reset_blog_post_category_state());
  }, [category_info.ID]);

  const dispatcher = useDispatch();

  const formSubmit = () => {
    dispatcher(set_blog_post_category_info({ touched: true }));
    if (
      category_info.name &&
      window.confirm("Are you sure you want to add this category?")
    ) {
      api_post_data("admin/add_blog_post_category", category_info)
        .then(async () => {
          await category_query.refetch();
          toggleDialog.next(false);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <>
      <Box
        className="p-4"
        style={{
          width: 500,
          maxWidth: "90vw",
        }}
      >
        <Typography className="mb-3">Thêm danh mục</Typography>
        <Row className="mb-4">
          <Col>
            <Blog_Post_Category_Editor />
          </Col>
        </Row>
        <Row>
          <Col className="text-end">
            <UI_Button variant="contained" onClick={formSubmit}>
              Xác nhận
            </UI_Button>
          </Col>
        </Row>
      </Box>
    </>
  );
};

export const Blog_Post_Category_Editor = () => {
  const dispatcher = useDispatch();

  const category_info = useSelector((e: AppState) => e.blog_post_category);

  return (
    <Box>
      <Row>
        <Col className="d-flex flex-column">
          <TextField
            className="mb-3"
            variant="standard"
            label="Tên"
            error={category_info.touched && category_info.name == ""}
            onChange={(e) => {
              dispatcher(set_blog_post_category_info({ name: e.target.value }));
            }}
            value={category_info.name}
          />
          <TextField
            className="mb-3"
            variant="standard"
            type="number"
            label="Thứ tự"
            onChange={(e) => {
              dispatcher(
                set_blog_post_category_info({
                  order: e.target.value == "" ? 0 : parseInt(e.target.value),
                }),
              );
            }}
            value={category_info.order ? category_info.order : ""}
          />
          <Typography variant="caption" fontWeight="light">
            {`Số thử tự càng nhỏ thì ưu tiên càng cao`}
          </Typography>
        </Col>
      </Row>
    </Box>
  );
};

export const CategorySelector = () => {
  const dispatcher = useDispatch();

  const category_query = useQuery(gql_queries.all_blog_post_categories, {
    fetchPolicy: "cache-and-network",
  });

  const BlockInfo = useSelector((e: AppState) => e.blog_post);

  const [all_cats, set_all_cats] = useState<BlogPostCategoryState[]>([]);

  useEffect(() => {
    const cat_data: BlogPostCategoryState[] =
      category_query.data?.all_blog_post_categories;

    if (cat_data && cat_data.length > 0) {
      dispatcher(
        set_blog_post_info({
          category: cat_data[0].ID,
        }),
      );
      set_all_cats(cat_data);
    }
  }, [category_query]);

  const data_set = all_cats.map((item) => {
    return {
      value: item.ID ? item.ID : 0,
      label: item.name,
    };
  });

  function myChangeEvent(e: any) {
    dispatcher(
      set_blog_post_info({
        category: parseInt(e.target.value.toString())
          ? parseInt(e.target.value.toString())
          : 0,
      }),
    );
  }

  return (
    <>
      <FormLabel className="text-center">Danh mục</FormLabel>
      <Chakra_Selector
        value={BlockInfo.category}
        dataSet={data_set}
        onChange={myChangeEvent}
        placeholder="Danh mục"
      />
    </>
  );
};
