import { TableWithSelection_Body } from "./TableWithSelection_Body";
import { Col, Row } from "react-bootstrap";
import { createTheme, ThemeProvider } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { HeadCell } from "../../../models/interface/tableUI.interface";
import { useDispatch, useSelector } from "react-redux";
import {
  set_table_search_key,
  set_table_search_term,
} from "../../../store/reducers/table_UI.reducer";
import { AppState } from "../../../store/models/corestore.interface";
import { Chakra_Input } from "../Chakra_Input";
import { Chakra_Selector, ChakraSelectorDataSet } from "../Chakra_Selector";

interface TableWithSelectionProps {
  headCells: HeadCell[];
  tableData: any[];
  rowMainKey: string;
  deleteRowApiUrlSuffix?: string;
  afterDeleteRowFn?: () => void;
  refresh_table_fn: () => void;
  row_click_action?: (value: any) => void;
  table_title: string;
}

export const TableWithSelection = (props: TableWithSelectionProps) => {
  const theme_options = useSelector(
    (e: AppState) => e.app_overall_info.theme_options_purity_admin_dashboard,
  );

  const MUI_main_theme = createTheme(theme_options);

  const dispatcher = useDispatch();

  const searchTerm = useSelector((e: AppState) => e.table_UI.search_term);

  useEffect(() => {
    const data = [...props.headCells.filter((item) => item.filter)];
    if (data.length > 0) {
      dispatcher(set_table_search_term(data[0].id));
    }
  }, [props.headCells]);

  const [data_set, set_data_set] = useState<ChakraSelectorDataSet[]>();

  useEffect(() => {
    const newVal = props.headCells
      .filter((item) => item.filter)
      .map((item) => {
        return {
          value: item.id,
          label: item.label,
        };
      });
    set_data_set(newVal);
  }, [props.headCells]);

  const SearchBar = () => {
    return (
      <Row>
        <Col>
          <Chakra_Input
            onChange={(newVal) => {
              dispatcher(set_table_search_key(newVal));
            }}
            size="md"
            placeholder="Tìm kiếm..."
          />
        </Col>
        <Col>
          <Chakra_Selector
            value={searchTerm}
            dataSet={data_set ? data_set : []}
            onChange={(e) => {
              dispatcher(set_table_search_term(e.target.value));
            }}
            placeholder="Tìm theo..."
          />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <ThemeProvider theme={MUI_main_theme}>
        <Row className="mb-3">
          <Col>
            <SearchBar />
          </Col>
        </Row>
        <Row>
          <Col>
            <TableWithSelection_Body {...props} />
          </Col>
        </Row>
      </ThemeProvider>
    </>
  );
};
