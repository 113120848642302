import { useSelector } from "react-redux";
import { AppState } from "../../store/models/corestore.interface";
import { Button, createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import { ExtendButtonBase } from "@mui/material/ButtonBase";
import { ButtonTypeMap } from "@mui/material/Button/Button";

export const UI_Button: ExtendButtonBase<ButtonTypeMap> = (props: any) => {
  const theme_options = useSelector(
    (e: AppState) => e.app_overall_info.theme_options_purity_admin_dashboard,
  );

  const MUI_main_theme = createTheme(theme_options);

  return (
    <ThemeProvider theme={MUI_main_theme}>
      <Button {...props} />
    </ThemeProvider>
  );
};
