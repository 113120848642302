import { BgOuterBox } from "../FE_Common/LandingPage/BgOuterBox";
import { PurityAuthNavbar } from "../UI_Components/Purity_Auth_Navbar";
import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import theme from "../../purity/theme/theme";
import { useSelector } from "react-redux";
import { AppState } from "../../store/models/corestore.interface";
import { HomeTopVideo } from "../FE_Common/LandingPage/HomeTopVideo";
import { DemoPageNavBarContent } from "./HomePageSingleBlockDemoPage";

export const TopVideoDemoPage = () => {
  const OuterBoxDraft = BgOuterBox({
    bg: "#f0f0f0",
    xlHeight: "20vh",
    largeHeight: "20vh",
    phoneHeight: "20vh",
    tabletHeight: "20vh",
  });

  const top_video_info = useSelector((e: AppState) => e.topvideo_info);

  return (
    <>
      <ChakraProvider theme={theme}>
        <PurityAuthNavbar content={<DemoPageNavBarContent />} />
      </ChakraProvider>
      <OuterBoxDraft />
      <HomeTopVideo top_video_info={top_video_info} />
    </>
  );
};
