import { Flex, ResponsiveValue, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { MenuItems } from "../../models/menuItems";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UI_Button } from "./UI_Button";

export const PurityAuthNavbar = ({
  content,
  secondary,
}: {
  content: React.ReactElement;
  secondary?: boolean;
}) => {
  let navbarPosition: ResponsiveValue<any> = "fixed";

  let navbarBg = useColorModeValue(
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)",
  );

  let navbarBorder = useColorModeValue(
    "1.5px solid #FFFFFF",
    "1.5px solid rgba(255, 255, 255, 0.31)",
  );

  let navbarShadow = useColorModeValue(
    "0px 7px 23px rgba(0, 0, 0, 0.05)",
    "none",
  );
  let navbarFilter = useColorModeValue(
    "none",
    "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))",
  );
  let navbarBackdrop = "blur(21px)";

  if (secondary === true) {
    navbarBg = "none";
    navbarBorder = "none";
    navbarShadow = "initial";
    navbarFilter = "initial";
    navbarBackdrop = "none";
    navbarPosition = "absolute";
  }

  return (
    <Flex
      position={navbarPosition}
      top="16px"
      left="50%"
      transform="translate(-50%, 0px)"
      background={navbarBg}
      border={navbarBorder}
      boxShadow={navbarShadow}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      borderRadius="15px"
      px="16px"
      py="22px"
      mx="auto"
      width="1044px"
      maxW="90%"
      alignItems="center"
      zIndex="10000"
    >
      {content}
    </Flex>
  );
};

export const Purity_Auth_Navbar_MenuItems = (props: {
  secondary?: boolean;
}) => {
  const { ...myProps } = props;

  const navigate = useNavigate();

  return (
    <>
      <Row>
        {MenuItems.map((item, i) => (
          <React.Fragment key={i}>
            {item.auth_nav ? (
              <>
                <Col xs="auto">
                  <UI_Button onClick={() => navigate(item.link)}>
                    <Typography
                      variant="h6"
                      style={{
                        color: myProps.secondary ? "white" : "inherit",
                      }}
                    >
                      <span className="me-2">{item.icon}</span>
                      <span>{item.name}</span>
                    </Typography>
                  </UI_Button>
                </Col>
              </>
            ) : (
              <></>
            )}
          </React.Fragment>
        ))}
      </Row>
    </>
  );
};
