import { Box, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CustomSidebarContent } from "./CustomSidebarContent";

export const CustomAdminSidebar = (props: any) => {
  // to check for active links and opened collapses
  const variantChange = "0.2s linear";
  const outerBg = useColorModeValue("gray.50", "gray.700");

  const [light_bg, set_light_bg] = useState<string>("gray.50");
  const [dark_bg, set_dark_bg] = useState<string>("gray.700");
  const sidebarBg = useColorModeValue(light_bg, dark_bg);

  const [sidebar_radius, set_sidebar_radius] = useState<string>("16px");
  const [sidebar_margin, set_sidebar_margin] =
    useState<string>("16px 0px 16px 16px");

  useEffect(() => {
    if (props.sidebarVariant === "opaque") {
      set_light_bg("white");
      set_dark_bg("gray.600");
      set_sidebar_radius("16px");
      set_sidebar_margin("16px 0px 16px 16px");
    } else {
      set_light_bg("gray.50");
      set_dark_bg("gray.700");
    }
  }, [props.sidebarVariant]);

  // SIDEBAR
  return (
    <Box>
      <Box bg={outerBg} display={{ sm: "none", xl: "block" }} position="fixed">
        <Box
          bg={sidebarBg}
          transition={variantChange}
          style={{ overflowY: "scroll" }}
          w="260px"
          maxW="260px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          h="calc(100vh - 32px)"
          ps="20px"
          pe="20px"
          m={sidebar_margin}
          borderRadius={sidebar_radius}
        >
          <CustomSidebarContent
            routes={props.routes}
            logoText={"DASHBOARD"}
            display="none"
            sidebarVariant={props.sidebarVariant}
          />
        </Box>
      </Box>
    </Box>
  );
};
