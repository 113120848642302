import { createTheme, TextField, ThemeProvider } from "@mui/material";
import React from "react";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { useSelector } from "react-redux";
import { AppState } from "../../store/models/corestore.interface";

export const UI_TextField = (props: TextFieldProps) => {
  const theme_options = useSelector(
    (e: AppState) => e.app_overall_info.theme_options_purity_admin_dashboard,
  );

  const MUI_main_theme = createTheme(theme_options);

  return (
    <ThemeProvider theme={MUI_main_theme}>
      <TextField {...props} />
    </ThemeProvider>
  );
};
