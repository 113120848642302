import {
  Box,
  ChakraProvider,
  CSSReset,
  Portal,
  ColorModeProvider,
} from "@chakra-ui/react";
import Footer from "../../purity/components/Footer/Footer";
import React from "react";
import theme from "../../purity/theme/theme";
import {
  Purity_Auth_Navbar_MenuItems,
  PurityAuthNavbar,
} from "../UI_Components/Purity_Auth_Navbar";
import { Col, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { globalSettings } from "../../environments/environments";
import { GoogleOAuthProvider } from "@react-oauth/google";

export const AuthLayout = () => {
  const navRef = React.useRef<any>();

  return (
    <ChakraProvider theme={theme}>
      <ColorModeProvider>
        <CSSReset />
        <Box ref={navRef} w="100%">
          <Portal containerRef={navRef}>
            <PurityAuthNavbar
              secondary={true}
              content={
                <Row className="justify-content-center w-100">
                  <Col xs="auto">
                    <Purity_Auth_Navbar_MenuItems secondary={true} />
                  </Col>
                </Row>
              }
            />
          </Portal>
          <GoogleOAuthProvider clientId={globalSettings.google_client_id}>
            <Outlet />
          </GoogleOAuthProvider>
          <Box px="24px" mx="auto" width="1044px" maxW="100%">
            <Footer />
          </Box>
        </Box>
      </ColorModeProvider>
    </ChakraProvider>
  );
};
