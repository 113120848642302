import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  ResponsiveValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { MyBreadCrumb } from "../../UI_Components/UI_Breadcrumbs";
import { CustomAdminNavBarLinks } from "./CustomAdminNavBarLinks";

const none_init = {
  light: "none",
  dark: "none",
};

const transparent_init = {
  light: "transparent",
  dark: "transparent",
};

export const CustomAdminNavBar = (props: any) => {
  const [scrolled, setScrolled] = useState(false);
  const { variant, children, fixed, secondary, brandText, onOpen, ...rest } =
    props;

  const [navbarPosition, setnavbarPosition] =
    useState<ResponsiveValue<any>>("absolute");
  const [navbarShadowStl, setnavbarShadowStl] = useState<{
    light: string;
    dark: string;
  }>(none_init);

  const [navbarBgStl, setnavbarBgStl] = useState<{
    light: string;
    dark: string;
  }>(none_init);

  const [navbarBorderStl, setnavbarBorderStl] = useState<{
    light: string;
    dark: string;
  }>(transparent_init);

  const [navbarFilterStl, setnavbarFilterStl] = useState<{
    light: string;
    dark: string;
  }>(none_init);

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue("gray.700", "gray.200");
  let secondaryText = useColorModeValue("gray.400", "gray.200");
  const navbarFilter = useColorModeValue(
    navbarFilterStl.light,
    navbarFilterStl.dark,
  );
  let navbarBackdrop = "blur(21px)";
  const navbarShadow = useColorModeValue(
    navbarShadowStl.light,
    navbarShadowStl.dark,
  );
  const navbarBg = useColorModeValue(navbarBgStl.light, navbarShadowStl.dark);
  const navbarBorder = useColorModeValue(
    navbarBorderStl.light,
    navbarBorderStl.dark,
  );
  let secondaryMargin = "0px";
  let paddingX = "15px";
  useEffect(() => {
    if (props.fixed === true) {
      if (scrolled === true) {
        setnavbarPosition("fixed");
        setnavbarShadowStl({
          light: "0px 7px 23px rgba(0, 0, 0, 0.05)",
          dark: "none",
        });
        setnavbarBgStl({
          light:
            "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
          dark: "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)",
        });
        setnavbarBorderStl({
          light: "#FFFFFF",
          dark: "rgba(255, 255, 255, 0.31)",
        });
        setnavbarFilterStl({
          light: "none",
          dark: "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))",
        });
      } else {
        setnavbarShadowStl(none_init);
        setnavbarBgStl(none_init);
        setnavbarBorderStl(transparent_init);
        setnavbarFilterStl(none_init);
      }
    } else {
      setnavbarPosition("absolute");
      setnavbarShadowStl(none_init);
      setnavbarBgStl(none_init);
      setnavbarBorderStl(transparent_init);
      setnavbarFilterStl(none_init);
    }

    if (props.secondary) {
      navbarBackdrop = "none";
      setnavbarPosition("absolute");
      mainText = "white";
      secondaryText = "white";
      secondaryMargin = "22px";
      paddingX = "30px";
    }
  }, [props.fixed, scrolled, props.secondary]);

  const changeNavbar = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  window.addEventListener("scroll", changeNavbar);
  return (
    <Flex
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "center" }}
      borderRadius="16px"
      display="flex"
      minH="75px"
      justifyContent={{ xl: "center" }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      left={document.documentElement.dir === "rtl" ? "30px" : ""}
      right={document.documentElement.dir === "rtl" ? "" : "30px"}
      px={{
        sm: paddingX,
        md: "30px",
      }}
      ps={{
        xl: "12px",
      }}
      pt="8px"
      top="18px"
      w={{ sm: "calc(100vw - 30px)", xl: "calc(100vw - 75px - 275px)" }}
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: "column",
          md: "row",
        }}
        alignItems={{ xl: "center" }}
      >
        <Box mb={{ sm: "8px", md: "0px" }}>
          <MyBreadCrumb start_seite_name="Dashboard" is_dynamic={false} />
        </Box>
        <Box ms="auto" w={{ sm: "100%", md: "unset" }}>
          <CustomAdminNavBarLinks
            onOpen={props.onOpen}
            logoText={props.logoText}
            secondary={props.secondary}
            fixed={props.fixed}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
