import {
  ChakraProvider,
  ColorModeProvider,
  FormControl,
  Select,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { AppState } from "../../store/models/corestore.interface";
import theme from "../../purity/theme/theme";
import React from "react";

export interface ChakraSelectorDataSet {
  value: string | number;
  label: string;
}

export const Chakra_Selector = ({
  value,
  dataSet,
  onChange,
  placeholder,
}: {
  value: string | number;
  dataSet: ChakraSelectorDataSet[];
  onChange: (val: any) => any;
  placeholder: string;
}) => {
  const theme_options = useSelector(
    (e: AppState) => e.app_overall_info.theme_options_purity_admin_dashboard,
  );

  return (
    <ChakraProvider theme={theme}>
      <ColorModeProvider>
        <FormControl>
          <Select
            onChange={(e) => (e.target.value ? onChange(e) : false)}
            placeholder={placeholder}
            focusBorderColor={theme_options.palette.primary.main}
            mb="24px"
            value={value}
          >
            {dataSet.map((item, i) => (
              <option key={i} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
        </FormControl>
      </ColorModeProvider>
    </ChakraProvider>
  );
};
