import { Flex, FormLabel } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import React, { useEffect, useState } from "react";
import Card from "../../purity/components/Card/Card";
import CardBody from "../../purity/components/Card/CardBody";
import { Col, Row } from "react-bootstrap";
import { api_post_data } from "../../environments/api_handler";
import { UI_Button } from "../UI_Components/UI_Button";
import { CheckIcon } from "@chakra-ui/icons";
import { jodit_config } from "../../models/customJoditEditor";
import JoditEditor from "jodit-react";

interface DatenschutzData {
  content: string;
}

const ActionButtons = ({
  page_data,
  data_key_name,
}: {
  page_data: DatenschutzData;
  data_key_name: string;
}) => {
  const navigate = useNavigate();

  return (
    <Row>
      <Col xs="auto">
        <UI_Button color="warning" onClick={() => navigate(-1)}>
          <span>
            <i className="fa-regular fa-left-long" />
          </span>
        </UI_Button>
      </Col>
      <Col xs="auto">
        <UI_Button
          color="success"
          onClick={async () => {
            await api_post_data("admin/edit_overall_information", {
              [data_key_name]: page_data,
            });
            return;
          }}
        >
          <CheckIcon className="me-2" />
          <span>Lưu</span>
        </UI_Button>
      </Col>
    </Row>
  );
};

export const DatenschutzPageInfoEditor = () => {
  const [page_data, set_page_data] = useState<DatenschutzData>({ content: "" });

  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const data =
      overall_information_query.data?.overall_information
        ?.datenschutz_page_info;

    if (!data) return;

    set_page_data(data);
  }, [overall_information_query.data]);

  const [jodit_content, set_jodit_content] = useState("");

  useEffect(() => {
    const data =
      overall_information_query.data?.overall_information.datenschutz_page_info;

    if (data && data.content) {
      set_jodit_content(data.content);
    }
  }, [overall_information_query.data]);

  return (
    <>
      <Flex
        direction="column"
        pt={{ base: "120px", md: "75px" }}
        className="gap-3"
      >
        <FormLabel>Chỉnh sửa thông tin Datenschutz</FormLabel>
        <Row>
          <Col>
            <Card p="16px">
              <CardBody px="5px" className="flex-column">
                <FormLabel>Nội dung Datenschutz:</FormLabel>
                <JoditEditor
                  value={jodit_content}
                  config={jodit_config}
                  onChange={(newContent) => {
                    set_page_data({
                      content: newContent,
                    });
                  }}
                />
                <Row className="mt-3 justify-content-end">
                  <Col xs="auto">
                    <ActionButtons
                      data_key_name="datenschutz_page_info"
                      page_data={page_data}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Flex>
    </>
  );
};
