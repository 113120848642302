import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { globalSettings } from "./environments/environments";
import { Outlet } from "react-router-dom";
import { Window_Resize_Detector } from "./components/shared/window_resize";
import { useSelector } from "react-redux";
import { AppState } from "./store/models/corestore.interface";

function App() {
  const theme_options = useSelector(
    (e: AppState) => e.app_overall_info.theme_options_purity_admin_dashboard,
  );

  const MUI_main_theme = createTheme(theme_options);

  return (
    <>
      {theme_options ? (
        <ThemeProvider theme={MUI_main_theme}>
          <HelmetProvider>
            <Helmet>
              <title>Default title</title>
              <link rel="icon" href="/favicon.ico" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
              />
            </Helmet>
            <div
              className="App"
              style={{ fontFamily: globalSettings.fontPrimary }}
            >
              <Window_Resize_Detector />
              <Outlet />
            </div>
          </HelmetProvider>
        </ThemeProvider>
      ) : (
        <></>
      )}
    </>
  );
}

export default App;
