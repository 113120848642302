import { Flex, FormLabel, Text, useColorModeValue } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/models/corestore.interface";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import React, { useEffect } from "react";
import Card from "../../purity/components/Card/Card";
import { set_footer_contactlogo_info } from "../../store/reducers/footer_contact_logo_info.reducer";
import { Alert, Typography } from "@mui/material";
import CardHeader from "../../purity/components/Card/CardHeader";
import CardBody from "../../purity/components/Card/CardBody";
import { Col, Row } from "react-bootstrap";
import { Chakra_Input } from "../UI_Components/Chakra_Input";
import { BlockEditorArrTypeActionButtons } from "./children/BlockEditorArrTypeActionButtons";

const ActionButtons = () => {
  const footer_contact_logos = useSelector(
    (e: AppState) => e.footer_contactlogo_info,
  );

  return (
    <BlockEditorArrTypeActionButtons dataToSave={{ footer_contact_logos }} />
  );
};

export const FooterContactLogoEditor = () => {
  const textColor = useColorModeValue("gray.700", "white");

  const BlockInfo = useSelector((e: AppState) => e.footer_contactlogo_info);

  const params = useParams();

  const dispatcher = useDispatch();

  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const data: any[] =
      overall_information_query.data?.overall_information?.footer_contact_logos;

    if (!data) return;

    const found = data.find((item: any) => item.ID == params.block_ID);
    if (!found) return;

    dispatcher(set_footer_contactlogo_info(found));
  }, [overall_information_query.data, params]);

  return (
    <>
      <Flex
        direction="column"
        pt={{ base: "120px", md: "75px" }}
        className="gap-3"
      >
        <FormLabel>Chỉnh sửa logo chân trang</FormLabel>
        <Alert color="info">Bạn đang chỉnh sửa logo #{BlockInfo.ID}</Alert>
        <Card p="16px">
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Nội dung
            </Text>
          </CardHeader>
          <CardBody px="5px" className="flex-column">
            <Row>
              <Col xs="12" md="6">
                <FormLabel>Kiểu logo</FormLabel>
                <Chakra_Input
                  onChange={(newContent: string) => {
                    dispatcher(
                      set_footer_contactlogo_info({
                        logo: newContent,
                      }),
                    );
                  }}
                  value={BlockInfo.logo}
                  placeholder="Nhập tên 1 thương hiệu. Vd: google, facebook..."
                />
                <Typography variant="caption" className="mb-2">
                  <b>Link tham khảo:</b>{" "}
                  <a
                    target="_blank"
                    href="https://fontawesome.com/search?s=brands"
                    rel="noreferrer"
                  >
                    Click để xem
                  </a>
                </Typography>
                <FormLabel>Kết quả:</FormLabel>
                <Typography variant="h4">
                  <i className={`fa-brands fa-${BlockInfo.logo}`} />
                </Typography>
              </Col>
              <Col xs="12" md="6">
                <Row className="flex-column justify-content-between h-100">
                  <Col xs="auto">
                    <FormLabel>Liên kết url</FormLabel>
                    <Chakra_Input
                      onChange={(newContent: string) => {
                        dispatcher(
                          set_footer_contactlogo_info({
                            url: newContent,
                          }),
                        );
                      }}
                      value={BlockInfo.url}
                      placeholder="Liên kết url"
                    />
                  </Col>
                  <Col xs="auto">
                    <Row className="justify-content-center">
                      <Col xs="auto">
                        <ActionButtons />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Flex>
    </>
  );
};
