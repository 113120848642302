import React, { useEffect, useRef, useState } from "react";
import {
  HomePageSingleBlockContent,
  HomePageSingleBlockMediaTypes,
  HomePageSingleBlockModes,
  HomePageSingleBlockState,
  HomePageSingleBlockTypes,
} from "../../../store/reducers/homepage_singleblock_info.reducer";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/models/corestore.interface";
import { all_screen_size } from "../../../store/reducers/screensize.reducer";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import { BgOuterBox } from "./BgOuterBox";
import { BigBoxInner } from "./others";
import { VerticalLines } from "./verticallines/vertical_lines";
import { cdnUrl } from "../../../environments/environments";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../../models/interface/gql_queries";

const HomePageTitleBox = (props: {
  type: HomePageSingleBlockTypes;
  mode: HomePageSingleBlockModes;
  content: HomePageSingleBlockContent;
}) => {
  const { type, mode, content } = props;

  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_small_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_xl() {
    switch (screen_size) {
      case all_screen_size.xs:
        return false;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return true;
      case all_screen_size.xxl:
        return true;
    }
  }

  const myBoxRef = useRef<any>(null);

  const [outer_box_stl, set_outer_box_stl] = useState<React.CSSProperties>({});
  const [inner_box_stl, set_inner_box_stl] = useState<React.CSSProperties>({});

  useEffect(() => {
    switch (type) {
      case "A":
        set_outer_box_stl({
          position: "absolute",
          top: "8%",
          width: is_small_phone()
            ? "60vw"
            : is_mobile()
            ? "30vw"
            : `${60 * 0.25}rem`,
          height: is_small_phone() ? "60vw" : is_mobile() ? "30vw" : "25%",
          left: is_small_phone()
            ? "50%"
            : is_mobile()
            ? "calc(1rem + (-100vw + 100%)/ 2)"
            : 0,
          transform: is_small_phone() ? "translateX(-50%)" : "",
          minWidth: is_mobile() ? "unset" : "280px",
          minHeight: is_mobile() ? "unset" : "280px",
        });
        break;
      case "B":
        set_outer_box_stl({
          position: "absolute",
          top: 0,
          width: is_small_phone()
            ? "60vw"
            : is_mobile()
            ? "30vw"
            : `${60 * 0.25}rem`,
          height: is_small_phone() ? "60vw" : is_mobile() ? "30vw" : "25%",
          left: is_small_phone()
            ? "50%"
            : is_mobile()
            ? "calc(1rem + (-100vw + 100%)/ 2)"
            : 0,
          transform: is_small_phone()
            ? "translate(-50%, -50%)"
            : "translateY(-50%)",
          minWidth: is_mobile() ? "unset" : "280px",
          minHeight: is_mobile() ? "unset" : "280px",
        });
        break;
      case "C":
        set_outer_box_stl({
          position: "absolute",
          top: 0,
          width: is_small_phone()
            ? "60vw"
            : is_mobile()
            ? "30vw"
            : `${60 * 0.25}rem`,
          height: is_small_phone() ? "60vw" : is_mobile() ? "30vw" : "25%",
          left: is_small_phone()
            ? "50%"
            : is_mobile()
            ? "calc(1rem + (-100vw + 100%)/ 2)"
            : 0,
          transform: is_small_phone()
            ? "translate(-50%, -50%)"
            : "translateY(-50%)",
          minWidth: is_mobile() ? "unset" : "280px",
          minHeight: is_mobile() ? "unset" : "280px",
        });
        break;
    }
  }, [type]);

  useEffect(() => {
    switch (mode) {
      case "light":
        set_inner_box_stl({
          background: "#212121",
          color: "#fff",
          border: "#fff solid 1px",
        });
        break;
      case "dark":
        set_inner_box_stl({
          background: "#fff",
          color: "#000",
          border: "#000 solid 1px",
        });
        break;
    }
  }, [mode]);

  const TitleBox = styled(Box)`
    width: 100%;
    max-width: 100%;
    min-width: ${is_mobile() ? "auto" : "280px"};
    padding: ${is_mobile() ? "1rem" : "2rem"};
    height: 100%;
  `;

  return (
    <>
      <Box style={outer_box_stl} className="z-13">
        <TitleBox ref={myBoxRef} style={inner_box_stl}>
          <Box className="h-100 w-100">
            <Container className="h-100">
              <Row className="h-100 flex-column justify-content-between align-items-center text-start">
                <Col className="z-10">
                  <Typography
                    variant={is_mobile() ? "body1" : "h6"}
                    fontWeight="600"
                  >
                    {content.title}
                  </Typography>
                </Col>
                <Col xs="auto">
                  {mode == HomePageSingleBlockModes.light ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      viewBox="0 0 800 800"
                      height={is_mobile() ? 50 : 100}
                    >
                      <g
                        strokeWidth="10"
                        stroke="hsl(0, 0%, 100%)"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        transform="rotate(45, 400, 400)"
                      >
                        <line
                          x1="250"
                          y1="250"
                          x2="550"
                          y2="550"
                          markerEnd="url(#SvgjsMarker1054)"
                        />
                      </g>
                      <defs>
                        <marker
                          markerWidth="5"
                          markerHeight="5"
                          refX="2.5"
                          refY="2.5"
                          viewBox="0 0 5 5"
                          orient="auto"
                          id="SvgjsMarker1054"
                        >
                          <polygon
                            points="0,5 1.6666666666666667,2.5 0,0 5,2.5"
                            fill="hsl(0, 0%, 100%)"
                          />
                        </marker>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      viewBox="0 0 800 800"
                      height={is_mobile() ? 50 : 100}
                    >
                      <g
                        strokeWidth="10"
                        stroke="hsl(0, 0%, 0%)"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        transform="rotate(45, 400, 400)"
                      >
                        <line
                          x1="250"
                          y1="250"
                          x2="550"
                          y2="550"
                          markerEnd="url(#SvgjsMarker1037)"
                        />
                      </g>
                      <defs>
                        <marker
                          markerWidth="5"
                          markerHeight="5"
                          refX="2.5"
                          refY="2.5"
                          viewBox="0 0 5 5"
                          orient="auto"
                          id="SvgjsMarker1037"
                        >
                          <polygon
                            points="0,5 1.6666666666666667,2.5 0,0 5,2.5"
                            fill="hsl(0, 0%, 0%)"
                          />
                        </marker>
                      </defs>
                    </svg>
                  )}
                </Col>
              </Row>
            </Container>
          </Box>
        </TitleBox>
      </Box>
    </>
  );
};

HomePageTitleBox.displayName = "HomePageTitleBox";

const HomePageContentBox = (props: {
  type: HomePageSingleBlockTypes;
  mode: HomePageSingleBlockModes;
  content: HomePageSingleBlockContent;
}) => {
  const { type, mode, content } = props;

  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_xl() {
    switch (screen_size) {
      case all_screen_size.xs:
        return false;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return true;
      case all_screen_size.xxl:
        return true;
    }
  }

  function is_small_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const [outer_box_stl, set_outer_box_stl] = useState<React.CSSProperties>({});
  const [inner_box_stl, set_inner_box_stl] = useState<React.CSSProperties>({});

  useEffect(() => {
    switch (type) {
      case "A":
        set_outer_box_stl({
          position: "absolute",
          top: is_small_phone()
            ? "78vw"
            : is_mobile()
            ? "38vw"
            : is_xl()
            ? "34%"
            : "34%",
          width: is_small_phone()
            ? "80vw"
            : is_mobile()
            ? "65vw"
            : is_xl()
            ? `${69 * 0.44}rem`
            : `${69 * 0.44}rem`,
          height: is_small_phone()
            ? "80vw"
            : is_mobile()
            ? "65vw"
            : is_xl()
            ? "44%"
            : "44%",
          transform: is_small_phone() ? "translateX(-50%)" : "",
          left: is_small_phone() ? "50%" : is_mobile() ? "8vw" : "18%",
        });
        break;
      case "B":
        set_outer_box_stl({
          position: "absolute",
          top: is_small_phone()
            ? "132vw"
            : is_mobile()
            ? "20vw"
            : is_xl()
            ? "21%"
            : "21%",
          width: is_small_phone()
            ? "80vw"
            : is_mobile()
            ? "65vw"
            : is_xl()
            ? `${69 * 0.44}rem`
            : `${69 * 0.44}rem`,
          height: is_small_phone()
            ? "99vw"
            : is_mobile()
            ? "65vw"
            : is_xl()
            ? "44%"
            : "44%",
          transform: is_small_phone() ? "translateX(-50%)" : "",
          left: is_small_phone() ? "50%" : is_mobile() ? "8vw" : "18%",
        });
        break;
      case "C":
        set_outer_box_stl({
          position: "absolute",
          top: is_small_phone()
            ? "43vw"
            : is_mobile()
            ? "14%"
            : is_xl()
            ? "12%"
            : "12%",
          width: is_small_phone()
            ? "80vw"
            : is_phone()
            ? "70vw"
            : is_mobile()
            ? "58vw"
            : is_xl()
            ? `${69 * 0.44}rem`
            : `${69 * 0.44}rem`,
          height: is_small_phone()
            ? "99vw"
            : is_phone()
            ? "70vw"
            : is_mobile()
            ? "58vw"
            : is_xl()
            ? "44%"
            : "44%",
          transform: is_small_phone() ? "translateX(-50%)" : "",
          left: is_small_phone()
            ? "50%"
            : is_phone()
            ? "4%"
            : is_mobile()
            ? "8vw"
            : "17%",
        });
        break;
    }
  }, [type]);

  useEffect(() => {
    switch (mode) {
      case "light":
        set_inner_box_stl({
          background: "transparent",
          color: "#000",
          border: "#000 solid 1px",
        });
        break;
      case "dark":
        set_inner_box_stl({
          background: "transparent",
          color: "#fff",
          border: "#fff solid 1px",
        });
        break;
    }
  }, [mode]);

  const ContentBox = styled(Box)`
    width: 100%;
    min-width: 280px;
    height: 100%;
    padding: ${is_small_phone()
      ? "1rem"
      : is_mobile()
      ? "2rem 1rem"
      : "4rem 2rem"};
    overflow: hidden;
  `;

  return (
    <>
      <Box style={outer_box_stl} className="z-15">
        <ContentBox style={inner_box_stl}>
          <Container className="text-start">
            <Row className="mb-3">
              <Col>
                <Box
                  style={{
                    width: 19,
                    height: 19,
                    background: mode == "light" ? "#000" : "#fff",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col className="z-10">
                <Typography
                  variant={is_mobile() ? "body1" : "h6"}
                  fontWeight="600"
                >
                  {content.content_bold}
                </Typography>
                <Typography
                  variant={is_mobile() ? "body1" : "h6"}
                  fontWeight="300"
                >
                  {content.content_light}
                </Typography>
              </Col>
            </Row>
          </Container>
        </ContentBox>
      </Box>
    </>
  );
};

HomePageContentBox.displayName = "HomePageContentBox";

const HomePageImageBox = (props: {
  type: HomePageSingleBlockTypes;
  mediaType: HomePageSingleBlockMediaTypes;
  media_url: string;
}) => {
  const { type, mediaType, media_url } = props;

  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  const get_outer_box_style = (): React.CSSProperties => {
    switch (type) {
      case "A":
        return {
          position: "absolute",
          top: is_small_phone() ? "163vw" : is_mobile() ? "98vw" : "15%",
          left: is_mobile() ? "unset" : is_xl() ? "52%" : "59%",
          right: is_mobile() ? "calc(0px + (-100vw + 100%)/ 2)" : "unset",
          height: is_mobile() ? "52vw" : is_xl() ? "53%" : "43%",
          width: is_mobile()
            ? "52vw"
            : is_xl()
            ? `${60 * 0.53}rem`
            : `${60 * 0.43}rem`,
          overflow: "hidden",
        };
      case "B":
        return {
          position: "absolute",
          top: is_small_phone() ? "11vw" : is_mobile() ? "38%" : 0,
          right: is_mobile()
            ? "calc(0px + (-100vw + 100%)/ 2)"
            : is_xl()
            ? 0
            : "calc(0px + (-100vw + 100%)/ 2)",
          height: is_small_phone() ? "114vw" : is_mobile() ? "59%" : "100%",
          width: is_small_phone() ? "100%" : "auto",
          overflow: "hidden",
        };
      case "C":
        return {
          position: "absolute",
          top: is_small_phone()
            ? "163vw"
            : is_mobile()
            ? "88vw"
            : is_xl()
            ? "44%"
            : "53%",
          left: is_mobile() ? "unset" : is_xl() ? "45%" : "29%",
          right: is_mobile() ? "calc(0px + (-100vw + 100%)/ 2)" : "unset",
          height: is_mobile() ? "42vw" : is_xl() ? "37%" : "34%",
          width: is_mobile() ? "79vw" : is_xl() ? `56%` : `69%`,
          overflow: "hidden",
        };
    }
    return {};
  };

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_xl() {
    switch (screen_size) {
      case all_screen_size.xs:
        return false;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return true;
      case all_screen_size.xxl:
        return true;
    }
  }

  function is_small_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  return (
    <>
      <Box
        style={get_outer_box_style()}
        className="z-10 d-flex justify-content-end"
      >
        {mediaType == "image" ? (
          <>
            <img
              src={`${cdnUrl}/${media_url}`}
              style={{
                objectFit: "cover",
                height: "100%",
              }}
            />
          </>
        ) : (
          <>
            <video
              autoPlay
              loop
              preload="metadata"
              playsInline
              muted
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
                maxHeight: "100%",
              }}
              data-hd="true"
            >
              <source src={`${cdnUrl}/${media_url}`} type="video/mp4" />
              {`Your browser doesn't support HTML5 video tag.`}
            </video>
          </>
        )}
      </Box>
    </>
  );
};

HomePageImageBox.displayName = "HomePageImageBox";

export const MyHomePageSingleBlock = (props: {
  blockID?: string;
  target_page?: string;
}) => {
  const overall_information_query = useQuery(gql_queries.overall_information);

  const initial_block_value = useSelector(
    (e: AppState) => e.homepage_singleblock_info,
  );

  const [all_blocks_data, set_all_blocks_data] = useState<
    HomePageSingleBlockState[] | null
  >(null);

  const [BlockInfo, setBlockInfo] =
    useState<HomePageSingleBlockState>(initial_block_value);

  useEffect(() => {
    if (props.blockID) {
      const raw_data = overall_information_query.data?.overall_information;
      if (raw_data && props.target_page && raw_data[props.target_page]) {
        set_all_blocks_data(raw_data[props.target_page]);
      }
    }
  }, [overall_information_query]);

  useEffect(() => {
    if (all_blocks_data && props.blockID) {
      const foundBlock = all_blocks_data.find(
        (item) => item.ID == props.blockID,
      );
      if (foundBlock) setBlockInfo(foundBlock);
    }
  }, [all_blocks_data]);

  const language = useSelector((e: AppState) => e.app_overall_info.language);

  const [largeHeight, setLargeHeight] = useState("74vw");
  const [tabletHeight, setTabletHeight] = useState("168vw");
  const [phoneHeight, setPhoneHeight] = useState("168vw");
  const [smallPhoneHeight, setSmallPhoneHeight] = useState("280vw");

  useEffect(() => {
    switch (BlockInfo.type) {
      case HomePageSingleBlockTypes.A:
        setSmallPhoneHeight("251vw");
        setLargeHeight("60rem");
        break;
      case HomePageSingleBlockTypes.B:
        setSmallPhoneHeight("280vw");
        setLargeHeight("60rem");
        break;
      case HomePageSingleBlockTypes.C:
        setTabletHeight("140vw");
        setPhoneHeight("144vw");
        setSmallPhoneHeight("220vw");
        break;
    }
  }, [BlockInfo]);

  const OuterBoxOne = BgOuterBox({
    bg: BlockInfo.mode == "light" ? "#f0f0f0" : "#212121",
    xlHeight: largeHeight,
    largeHeight: largeHeight,
    tabletHeight: tabletHeight,
    phoneHeight: phoneHeight,
    smallPhoneHeight: smallPhoneHeight,
  });

  const InnerBox = BigBoxInner({ fluid: false });

  const MyBoxOne = () => (
    <>
      {BlockInfo && BlockInfo.ID ? (
        <>
          <HomePageTitleBox
            type={BlockInfo.type}
            mode={BlockInfo.mode}
            content={BlockInfo[language]}
          />
          <HomePageContentBox
            type={BlockInfo.type}
            mode={BlockInfo.mode}
            content={BlockInfo[language]}
          />
          <HomePageImageBox
            type={BlockInfo.type}
            mediaType={BlockInfo.medias.main_img.type}
            media_url={BlockInfo.medias.main_img.url}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );

  return (
    <>
      {BlockInfo && BlockInfo.ID ? (
        <>
          <OuterBoxOne>
            <InnerBox>
              <MyBoxOne />
            </InnerBox>
            <VerticalLines
              bg={BlockInfo.mode == "dark" ? "#f0f0f0" : "#212121"}
            />
          </OuterBoxOne>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
