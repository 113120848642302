import { Flex, Grid, Text, useColorModeValue } from "@chakra-ui/react";
import CardHeader from "../../purity/components/Card/CardHeader";
import { CardContent, Typography } from "@mui/material";
import Card from "../../purity/components/Card/Card";
import React from "react";
import { UI_Button } from "../UI_Components/UI_Button";
import { api_post_data } from "../../environments/api_handler";
import CardBody from "../../purity/components/Card/CardBody";
import { MenuItems } from "../../models/menuItems";
import { useNavigate } from "react-router-dom";

export const DashboardHome = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const navigate = useNavigate();

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }} gap="4">
      <Grid
        templateColumns={{
          sm: "1fr",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
        }}
        gap="22px"
      >
        <Card p="16px">
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Thông tin chung
            </Text>
          </CardHeader>
          <CardBody px="5px">
            <Flex direction="column" w="100%">
              {MenuItems.filter(
                (item) => item.cat == 1 && item.on_dashboard,
              ).map((item, i) => (
                <Flex key={i} justifyContent="space-between" mb="21px">
                  <Flex align="center">
                    <Flex direction="column">
                      <Text fontSize="sm" color={textColor} fontWeight="bold">
                        {item.name}
                      </Text>
                    </Flex>
                  </Flex>
                  <UI_Button onClick={() => navigate(item.link)}>
                    <Typography>
                      <i className="fa-regular fa-pen-to-square" />
                    </Typography>
                  </UI_Button>
                </Flex>
              ))}
              {MenuItems.filter((item) => item.cat == 3).map((item, i) => (
                <Flex key={i} justifyContent="space-between" mb="21px">
                  <Flex align="center">
                    <Flex direction="column">
                      <Text fontSize="sm" color={textColor} fontWeight="bold">
                        {item.name}
                      </Text>
                    </Flex>
                  </Flex>
                  <UI_Button onClick={() => navigate(item.link)}>
                    <Typography>
                      <i className="fa-regular fa-pen-to-square" />
                    </Typography>
                  </UI_Button>
                </Flex>
              ))}
            </Flex>
          </CardBody>
        </Card>
        <Card p="16px">
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Sửa giao diện
            </Text>
          </CardHeader>
          <CardBody px="5px">
            <Flex direction="column" w="100%">
              {MenuItems.filter((item) => item.cat == 2).map((item, i) => (
                <Flex key={i} justifyContent="space-between" mb="21px">
                  <Flex align="center">
                    <Flex direction="column">
                      <Text fontSize="sm" color={textColor} fontWeight="bold">
                        {item.name}
                      </Text>
                    </Flex>
                  </Flex>
                  <UI_Button onClick={() => navigate(item.link)}>
                    <Typography>
                      <i className="fa-regular fa-pen-to-square" />
                    </Typography>
                  </UI_Button>
                </Flex>
              ))}
            </Flex>
          </CardBody>
        </Card>
        <Card p="16px">
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Blog
            </Text>
          </CardHeader>
          <CardContent className="d-flex flex-column gap-3">
            <UI_Button
              variant="contained"
              onClick={() => navigate("/manage_blog_posts")}
            >
              <Typography>
                <i className="fa-light fa-bars-progress me-2" />
                Danh sách bài viết
              </Typography>
            </UI_Button>
            <UI_Button
              color="success"
              variant="contained"
              onClick={() => navigate("/manage_blog_posts/add_more_post")}
            >
              <Typography>
                <i className="fa-light fa-plus me-2" />
                Thêm bài viết
              </Typography>
            </UI_Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        templateColumns={{
          sm: "1fr",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
        }}
        gap="22px"
      >
        <Card p="16px">
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Quản lý Media
            </Text>
          </CardHeader>
          <CardContent className="d-flex flex-column gap-3">
            <UI_Button
              variant="contained"
              color="error"
              onClick={async () => {
                await api_post_data("admin/delete_all_trash_medias");
              }}
            >
              <Typography>
                <i className="fa-regular fa-trash-can me-2" />
                Xóa hết media rác
              </Typography>
            </UI_Button>
          </CardContent>
        </Card>
      </Grid>
    </Flex>
  );
};
