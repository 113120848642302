import React from "react";
import { UserRoles } from "./interface/user_info.interface";

function role_expansion(role: UserRoles) {
  switch (role) {
    case UserRoles.guest:
      return [UserRoles.guest, UserRoles.admin, UserRoles.subscriber];
    case UserRoles.subscriber:
      return [UserRoles.admin, UserRoles.subscriber];
    case UserRoles.admin:
      return [UserRoles.admin];
  }
}

interface menuItem {
  name: React.ReactElement;
  icon: React.ReactElement | null;
  link: string;
  cat?: number;
  action: () => void;
  include_roles: string[];
  exclude_roles: string[];
  auth_nav?: boolean;
  on_dashboard?: boolean;
}

export const MenuItems: menuItem[] = [
  {
    name: <>Trang chủ</>,
    icon: <i className="fa-solid fa-house-heart" />,
    link: "/home",
    cat: 1,
    auth_nav: true,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
  },
  {
    name: <>Đăng nhập</>,
    icon: <i className="fa-light fa-arrow-right-to-bracket" />,
    link: "/auth/sign_in",
    cat: 1,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.subscriber),
    exclude_roles: role_expansion(UserRoles.subscriber),
  },
  {
    name: <>Block trang chủ</>,
    icon: null,
    link: "/manage_homepage_blocks",
    cat: 2,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
  },
  {
    name: <>Block trang cases</>,
    icon: null,
    link: "/manage_cases_page_blocks",
    cat: 2,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
  },
  {
    name: <>Quản lý top videos</>,
    icon: null,
    link: "/manage_topvideo_blocks",
    cat: 2,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
  },
  {
    name: <>Quản lý services blocks</>,
    icon: null,
    link: "/manage_services_page_blocks",
    cat: 2,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
  },
  {
    name: <>Service - Web Design blocks</>,
    icon: null,
    link: "/manage_blocks_webdesign",
    cat: 2,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
  },
  {
    name: <>Service - Graphic Design blocks</>,
    icon: null,
    link: "/manage_blocks_graphicdesign",
    cat: 2,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
  },
  {
    name: <>Service - NFC Card blocks</>,
    icon: null,
    link: "/manage_blocks_nfccard",
    cat: 2,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
  },
  {
    name: <>Service - Social Media blocks</>,
    icon: null,
    link: "/manage_blocks_socialmedia",
    cat: 2,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
  },
  {
    name: <>Partners trang chủ</>,
    icon: null,
    link: "/manage_homepage_partners",
    cat: 2,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
  },
  {
    name: <>Contact logos</>,
    icon: null,
    link: "/manage_footer_contact_logos",
    cat: 3,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
  },
  {
    name: <>Partner logos</>,
    icon: null,
    link: "/manage_footer_partner_logos",
    cat: 3,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
  },
  {
    name: <>Thông tin liên hệ</>,
    icon: null,
    link: "/edit_contact_page_information",
    cat: 2,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
  },
  {
    name: <>Nội dung Datenschutz</>,
    icon: null,
    link: "/edit_datenschutz_page_information",
    cat: 2,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
  },
  {
    name: <>Nội dung Impressum</>,
    icon: null,
    link: "/edit_impressum_page_information",
    cat: 2,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
  },
  {
    name: <>Tổng quan blog</>,
    icon: null,
    link: "/manage_blog_posts",
    cat: 4,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
  },
  {
    name: <>Thêm bài viết</>,
    icon: null,
    link: "/manage_blog_posts/add_more_post",
    cat: 4,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
  },
  {
    name: <>Thông tin tổng quát</>,
    icon: null,
    link: "/overall_information",
    cat: 1,
    action: () => {
      return "ok";
    },
    include_roles: role_expansion(UserRoles.admin),
    exclude_roles: [],
    on_dashboard: true,
  },
];

export interface MenuItemCategoryEntity {
  id: number;
  name: React.ReactElement;
  order: number;
  icon: React.ReactElement;
}

export const MenuItem_Categories: MenuItemCategoryEntity[] = [
  {
    id: 1,
    order: 100,
    name: <>Tổng quan</>,
    icon: <i className="fa-regular fa-chart-network" />,
  },
  {
    id: 2,
    order: 15,
    name: <>Quản lý</>,
    icon: <i className="fa-regular fa-bars-progress" />,
  },
  {
    id: 3,
    order: 13,
    name: <>Chân trang</>,
    icon: <i className="fa-regular fa-shoe-prints" />,
  },
  {
    id: 4,
    order: 35,
    name: <>Blog</>,
    icon: <i className="fa-regular fa-square-rss" />,
  },
];
